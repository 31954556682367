import React, {CSSProperties, PropsWithChildren, ReactElement, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled} from "@mui/material";
import ContentLoadingButton from "./ContentLoadingButton";

const DialogActionButton = styled(Button)(({theme}) => ({
    color: "#02A1A0",
}))

interface DialogLoadingButtonProps extends PropsWithChildren<any> {
    label: string | ReactElement,
    title: string,
    details?: string,
    variant?: "primary" | "secondary"
    actionLabel: string,
    pending: boolean,

    onActionClick(): void,

    buttonStyle?: CSSProperties,
    buttonClassName?: string,

    onValidateAction?(): boolean
}

function DialogLoadingButton(props: DialogLoadingButtonProps) {
    const {
        label, title, details, variant, actionLabel, pending, onActionClick,
        buttonStyle, buttonClassName, onValidateAction, children
    } = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleAction = () => {
        onActionClick();
        handleClose();
    }

    return (
        <>
            <ContentLoadingButton variant={variant}
                                  pending={open || pending}
                                  style={buttonStyle}
                                  className={buttonClassName}
                                  onClick={handleClickOpen}>{label}</ContentLoadingButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-button-title">
                <DialogTitle id="dialog-button-title">{title}</DialogTitle>
                <DialogContent style={{paddingBottom: `${children ? "0" : "20px"}`}}>
                    {details && (<DialogContentText style={{color: "#000000"}}>{details}</DialogContentText>)}
                    {children}
                </DialogContent>
                <DialogActions>
                    <DialogActionButton onClick={handleClose}>Abbrechen</DialogActionButton>
                    <DialogActionButton disabled={onValidateAction ? !onValidateAction() : false}
                                        onClick={handleAction}>{actionLabel}</DialogActionButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default (DialogLoadingButton);
