import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import TextGroup from "../../components/TextGroup";
import {createStyles, makeStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import TextRow from "../../components/TextRow";
import DialogButton from "../../components/DialogButton";
import BackgroundDropdown from "../../components/BackgroundDropdown";
import ContentButton from "../../components/ContentButton";
import Text from "../../components/Text";
import IMember, {
    DirectDebitAuthority,
    Gender,
    genderToText,
    IMemberBankAccount,
    IMemberContact,
    IMemberGroup,
    Membership,
    membershipToText,
    SailEntitlement
} from "../../model/member/IMember";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {groupToText, UserRole} from "../../model/IUser";
import {DatePicker} from "@mui/lab";
import {DateValidationError} from "@mui/lab/internal/pickers/date-utils";
import {ParseableDate} from "@mui/lab/internal/pickers/constants/prop-types";
import {StyledTextField} from "../../components/StyledComponents";
import BackgroundTextField from "../../components/BackgroundTextField";
import {handleDateToIsoString, handleMaxDate} from "../../model/DateHandler";
import {ContactServiceApi, FamilyServiceApi, MemberServiceApi} from "../../api/MemberServiceApi";
import MemberAddFamilyMember from "./MemberAddFamilyMember";
import EntitlementArea from "./EntitlementArea";
import {TextFieldProps} from "@mui/material";
import {IContact} from "../../model/member/IContact";
import {OptionType} from "../../components/BackgroundAutocomplete";
import DialogIconButton from "../../components/DialogIconButton";

const useStyles = makeStyles(() => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "#E8E7E8"
    },
    oneColumn: {
        width: "100%"
    },
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    middleColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "auto",
        marginRight: "auto"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
        marginRight: "0"
    },
    button: {
        marginRight: "10px"
    },
    lastButton: {
        marginLeft: "0",
        marginRight: "0"
    },
    familyDeleteButton: {
        marginLeft: "5px",
        marginTop: "8px",
        height: "40px"
    },
}));

interface MemberDetailProps extends PropsWithChildren<any> {
    member: IMember;

    onUpdate(member: IMember): void;

    onDelete(member: IMember): void;

    onChange(member: IMember): void;
}

export default function MemberDetail(props: MemberDetailProps) {
    const {member, onUpdate, onDelete, onChange} = props;
    const classes = useStyles();
    const {user, getToken} = useContext(AuthServiceContext);

    // model for account area
    const [membership, setMembership] = useState<Membership>(member.membership);
    const [familyMembers, setFamilyMembers] = useState<IMember[]>([]);
    // model for personal data area
    const [firstName, setFirstName] = useState<string>(member.firstName);
    const [lastName, setLastName] = useState<string>(member.lastName);
    const [gender, setGender] = useState<Gender>(member.gender);
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date(Date.parse(member.dateOfBirth)));
    // model for contact area
    const [streetLine, setStreetLine] = useState<string>(member.contact.address.streetLine);
    const [zipCode, setZipCode] = useState<string>(member.contact.address.zipCode);
    const [city, setCity] = useState<string>(member.contact.address.city);
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(member.contact.phoneNumber);
    const [mobileNumber, setMobileNumber] = useState<string | undefined>(member.contact.mobileNumber);
    // model for bank account area
    const [accountHolder, setAccountHolder] = useState<string>(member.bankAccount.accountHolder);
    const [iban, setIban] = useState<string>(member.bankAccount.iban);
    const [bic, setBic] = useState<string>(member.bankAccount.bic);
    const [bankName, setBankName] = useState<string>(member.bankAccount.bankName);
    // model for entitlement area
    const [directDebitAuthority, setDirectDebitAuthority] = useState<DirectDebitAuthority>(member.bankAccount.directDebitAuthority);
    const [sailEntitlement, setSailEntitlement] = useState<SailEntitlement>(member.sailEntitlement);
    // model for groups
    const [groups, setGroups] = useState<IMemberGroup[]>();
    // contact model
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [optionTypes, setOptionTypes] = useState<OptionType[]>([]);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            const memberGroups: IMemberGroup[] = await MemberServiceApi.getGroupsOfMember(member, getToken());
            setGroups(memberGroups);
            const contacts: IContact[] = await ContactServiceApi.getContacts(getToken());
            setContacts(contacts);
            console.info("member updated");
            if (member.membership === Membership.FAMILY) {
                const familyMembers = await FamilyServiceApi.getFamilyOfMember(member, getToken());
                setFamilyMembers([...familyMembers]);
                console.info("member is family and has members", familyMembers);
            }
        }
        fetchData()
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            });
        setMembership(member.membership);
    }, [member, getToken]);

    useEffect(() => {
        const optionTypes = contacts.filter(contact => member.identifier !== contact.identifier).map(contact => {
            return {title: contact.firstName + " " + contact.lastName, value: contact.identifier}
        });
        setOptionTypes(optionTypes);
    }, [member, contacts]);

    const handleAllowedToEdit = () => {
        return user.isInUserRole(UserRole.ADM_USER);
    }

    const handleSave = () => {
        // model for account
        member.membership = membership;
        // model for personal data
        member.firstName = firstName;
        member.lastName = lastName;
        member.gender = gender;
        member.dateOfBirth = handleDateToIsoString(dateOfBirth);
        // model for contact
        member.contact = convertContact();
        // model for bank account
        member.bankAccount = convertBankAccount();
        // model for entitlement
        member.sailEntitlement = sailEntitlement;
        onUpdate(member);
    }

    const handleStateChange = (state: string) => {
        MemberServiceApi.updateState(member, state, getToken())
            .then((updatedMember: IMember) => {
                onChange(updatedMember);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
    }

    const convertContact = (): IMemberContact => {
        return {
            address: {
                streetLine: streetLine,
                zipCode: zipCode,
                city: city
            },
            phoneNumber: phoneNumber,
            mobileNumber: mobileNumber
        };
    }

    const convertBankAccount = (): IMemberBankAccount => {
        return {
            accountHolder: accountHolder,
            iban: iban,
            bic: bic,
            bankName: bankName,
            directDebitAuthority: handleBankAccountModified() ? DirectDebitAuthority.NOT_GRANTED : directDebitAuthority
        };
    }

    const handleDelete = () => {
        onDelete(member);
    }

    const handleModified = (): boolean => {
        return (membership !== member.membership)
            || (firstName !== member.firstName)
            || (lastName !== member.lastName)
            || (gender !== member.gender)
            || (handleDateToIsoString(dateOfBirth) !== member.dateOfBirth)
            || (streetLine !== member.contact.address.streetLine)
            || (zipCode !== member.contact.address.zipCode)
            || (city !== member.contact.address.city)
            || (phoneNumber !== member.contact.phoneNumber)
            || (mobileNumber !== member.contact.mobileNumber)
            || handleBankAccountModified()
            || (directDebitAuthority !== member.bankAccount.directDebitAuthority)
            || (sailEntitlement !== member.sailEntitlement);
    }

    const handleBankAccountModified = (): boolean => {
        return (accountHolder !== member.bankAccount.accountHolder)
            || (iban !== member.bankAccount.iban)
            || (bic !== member.bankAccount.bic)
            || (bankName !== member.bankAccount.bankName);
    }

    const handleCreateFamily = () => {
        FamilyServiceApi.createFamily(member, getToken())
            .then((updatedMember: IMember) => {
                console.info("familie erstellen", updatedMember);
                onChange(updatedMember);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
    }

    const handleDeleteFamily = () => {
        FamilyServiceApi.deleteFamily(member, getToken())
            .then((updatedMembers: IMember[]) => {
                console.info("familie auflösen", updatedMembers);
                updatedMembers.forEach((updatedMember: IMember) => {
                    onChange(updatedMember);
                });
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
    }

    const handleMemberAddToFamily = (familyMemberToAdd: string) => {
        if (familyMemberToAdd !== "") {
            const subordinatedMemberIdentifier = {identifier: familyMemberToAdd};
            FamilyServiceApi.addMemberToFamily(member, subordinatedMemberIdentifier, getToken())
                .then((updatedMember: IMember) => {
                    console.info("add to family", updatedMember);
                    onChange(member);
                    onChange(updatedMember);
                })
                .catch((error) => {
                    console.error("unexpected error: " + error.message, error);
                    return false;
                });
            console.info(familyMemberToAdd);
        }
    }

    const handleMemberRemoveFromFamily = (familyMemberToRemove: string) => {
        if (familyMemberToRemove !== "") {
            const subordinatedMemberIdentifier = {identifier: familyMemberToRemove};
            FamilyServiceApi.removeMemberFromFamily(member, subordinatedMemberIdentifier, getToken())
                .then((updatedMember: IMember) => {
                    console.info("remove from family", updatedMember);
                    onChange(member);
                    onChange(updatedMember);
                })
                .catch((error) => {
                    console.error("unexpected error: " + error.message, error);
                    return false;
                });
            console.info(familyMemberToRemove);
        }
    }

    const isFamily = (): boolean => {
        return membership === Membership.FAMILY;
    }

    return (
        <div key={member.identifier} className={classes.container}>
            <TextGroup label="Konto">
                <TextRow>
                    <Text className={classes.leftColumn}
                          label="USCO-ID"
                          value={member.identifier}/>
                    <Text className={classes.middleColumn}
                          label="Nutzername"
                          value={member.username}/>
                    <Text className={classes.rightColumn}
                          label="E-Mail-Adresse"
                          value={member.mailAddress}/>
                </TextRow>
                <TextRow>
                    <BackgroundDropdown id="member-detail-membership-label"
                                        label="Art der Mitgliedschaft"
                                        className={classes.leftColumn}
                                        editable={handleAllowedToEdit() && !isFamily()}
                                        useValue={true}
                                        value={handleAllowedToEdit() && !isFamily() ? membership : membershipToText(membership)}
                                        defaultValue={member.membership}
                                        nonValue={Membership.NONE}
                                        errorMessage="Art der Mitgliedschaft nicht ausgewählt."
                                        validated={false}
                                        required={true}
                                        onChange={(event: any) => {
                                            setMembership(event.target.value);
                                        }}>
                        <MenuItem value={Membership.NONE} disabled>{membershipToText(Membership.NONE)}</MenuItem>
                        <MenuItem value={Membership.SINGLE}>{membershipToText(Membership.SINGLE)}</MenuItem>
                        <MenuItem value={Membership.JUNIOR}>{membershipToText(Membership.JUNIOR)}</MenuItem>
                        <MenuItem value={Membership.STUDENT}>{membershipToText(Membership.STUDENT)}</MenuItem>
                        <MenuItem value={Membership.FAMILY} disabled>{membershipToText(Membership.FAMILY)}</MenuItem>
                    </BackgroundDropdown>
                    {member.principleIdentifier && (
                        <Text className={classes.rightColumn}
                              label="Übergeordnetes Familienmitglied"
                              value={member.principleIdentifier}
                        />
                    )}
                    {!member.principleIdentifier && (
                        <DialogButton label={isFamily() ? "Familie auflösen" : "Familie erstellen"}
                                      title={`Familie wirklich ${isFamily() ? "auflösen" : "erstellen"}?`}
                                      details={`Hinweis: Durch das ${isFamily() 
                                          ? "Auflösen werden alle Familienmitglieder umgehend zum Einzelmitglied." 
                                          : "Erstellen wird umgehend eine Familienmitgliedschaft erstellt," +
                                          " zur der noch Mitglieder hinzugefügt werden müssen."}`}
                                      variant="secondary"
                                      actionLabel={isFamily() ? "Auflösen" : "Erstellen"}
                                      onActionClick={() => {
                                          if (member.membership !== Membership.FAMILY) {
                                              handleCreateFamily();
                                          } else {
                                              handleDeleteFamily();
                                          }
                                      }}
                                      buttonStyle={{marginLeft: "10px", marginTop: "6px"}}
                        />
                    )}
                </TextRow>
            </TextGroup>
            {!member.principleIdentifier && isFamily() && (
                <TextGroup label="Familie">
                    {familyMembers?.map((memberInFamily: IMember, index: number) => (
                        <TextRow key={index} last={familyMembers?.length === index}>
                            <Text className={classes.leftColumn}
                                  label="Untergeordnetes Familienmitglied"
                                  value={memberInFamily.firstName + " " + memberInFamily.lastName}
                            />
                            {handleAllowedToEdit() && (<DialogIconButton
                                icon="delete"
                                title="Nutzer wirklich aus Familie entfernen?"
                                details="Hinweis: Durch das Entfernen wird dem Nutzer umgehend zum Einzelmitglied."
                                actionLabel="Entfernen"
                                onActionClick={() => {
                                    handleMemberRemoveFromFamily(memberInFamily.identifier);
                                }}
                                buttonClassName={classes.familyDeleteButton}/>)}
                        </TextRow>
                    ))}
                    <MemberAddFamilyMember optionTypes={optionTypes}
                                           familyMembers={familyMembers}
                                           onAddMemberToFamily={handleMemberAddToFamily}/>
                </TextGroup>
            )}
            <TextGroup label="Persönliche Daten">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Vorname"
                                         defaultValue={member.firstName}
                                         onChange={(event: any) => {
                                             setFirstName(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Nachname"
                                         defaultValue={member.lastName}
                                         onChange={(event: any) => {
                                             setLastName(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow>
                    <BackgroundDropdown id="member-detail-gender-label"
                                        label="Geschlecht"
                                        className={classes.leftColumn}
                                        editable={handleAllowedToEdit()}
                                        useValue={true}
                                        value={handleAllowedToEdit() ? gender : genderToText(gender)}
                                        defaultValue={member.gender}
                                        nonValue={Gender.NONE}
                                        errorMessage="Geschlecht nicht ausgewählt."
                                        validated={false}
                                        required={true}
                                        onChange={(event: any) => {
                                            setGender(event.target.value);
                                        }}>
                        <MenuItem value={Gender.NONE} disabled>{genderToText(Gender.NONE)}</MenuItem>
                        <MenuItem value={Gender.MALE}>{genderToText(Gender.MALE)}</MenuItem>
                        <MenuItem value={Gender.FEMALE}>{genderToText(Gender.FEMALE)}</MenuItem>
                        <MenuItem value={Gender.DEVICE}>{genderToText(Gender.DEVICE)}</MenuItem>
                    </BackgroundDropdown>
                    <DatePicker
                        label="Geburtsdatum"
                        openTo="year"
                        views={["year", "day"]}
                        maxDate={handleMaxDate()}
                        value={dateOfBirth}
                        onChange={setDateOfBirth}
                        onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                            console.info(reason, value);
                        }}
                        renderInput={(params: TextFieldProps) =>
                            <StyledTextField {...params}
                                             className={classes.rightColumn}
                                             required
                                             label="Geburtsdatum"
                                             placeholder="Geburtsdatum"
                            />}
                        inputFormat="dd.MM.yyyy"
                        mask="__.__.____"
                    />
                </TextRow>
            </TextGroup>
            <TextGroup label="Anschrift und Kontakt">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Straße und Hausnummer"
                                         defaultValue={member.contact.address.streetLine}
                                         onChange={(event: any) => {
                                             setStreetLine(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.middleColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Postleitzahl"
                                         defaultValue={member.contact.address.zipCode}
                                         onChange={(event: any) => {
                                             setZipCode(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Stadt"
                                         defaultValue={member.contact.address.city}
                                         onChange={(event: any) => {
                                             setCity(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Festnetznummer"
                                         defaultValue={member.contact.phoneNumber}
                                         onChange={(event: any) => {
                                             setPhoneNumber(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Mobilnummer"
                                         defaultValue={member.contact.mobileNumber}
                                         onChange={(event: any) => {
                                             console.info("'" + event.target.value + "'");
                                             setMobileNumber(event.target.value);
                                         }}/>
                </TextRow>
            </TextGroup>
            <TextGroup label="Bankverbindung">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Kontoinhaber"
                                         defaultValue={member.bankAccount.accountHolder}
                                         onChange={(event: any) => {
                                             setAccountHolder(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="IBAN"
                                         defaultValue={member.bankAccount.iban}
                                         onChange={(event: any) => {
                                             setIban(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="BIC"
                                         defaultValue={member.bankAccount.bic}
                                         onChange={(event: any) => {
                                             setBic(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Bankinstitut"
                                         defaultValue={member.bankAccount.bankName}
                                         onChange={(event: any) => {
                                             setBankName(event.target.value);
                                         }}/>
                </TextRow>
            </TextGroup>
            <EntitlementArea member={member}
                             directDebitAuthority={directDebitAuthority}
                             sailEntitlement={sailEntitlement}
                             onChangeDirectDebitAuthority={setDirectDebitAuthority}
                             onChangeSailEntitlement={setSailEntitlement}/>
            <TextGroup label="Gruppenzugehörigkeit">
                {groups?.map((memberInGroup: IMemberGroup, index: number) => (
                    <TextRow key={index} last={groups?.length === index}>
                        <div>{groupToText(memberInGroup.group)}</div>
                    </TextRow>
                ))}
            </TextGroup>
            {handleAllowedToEdit() && (
                <div className={classes.buttonContainer} style={{marginTop: "20px", marginLeft: "0"}}>
                    <DialogButton label={member.locked ? "Entsperren" : "Sperren"}
                                  title={`Mitglied wirklich ${member.locked ? "entsperren" : "sperren"}?`}
                                  variant="secondary"
                                  actionLabel={member.locked ? "Entsperren" : "Sperren"}
                                  onActionClick={() => {
                                      handleStateChange(member.locked ? "UNLOCK" : "LOCK");
                                  }}
                                  buttonStyle={{marginLeft: "0", marginRight: "0"}}/>
                    <DialogButton label="Löschen"
                                  title="Mitglied wirklich löschen?"
                                  variant="secondary"
                                  actionLabel="Löschen"
                                  onActionClick={handleDelete}
                                  buttonClassName={classes.button}/>
                    <ContentButton className={classes.lastButton}
                                   variant="primary"
                                   disabled={!handleModified()}
                                   onClick={handleSave}>Speichern</ContentButton>

                </div>)}
        </div>
    );
}
