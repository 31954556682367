import React, {useContext, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import ContentButton from "../../components/ContentButton";
import DataTable, {DataHeaderColumn, DataTableRow} from "../../components/DataTable";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import NewAssociationWorking from "./NewAssociationWorking";
import {UserRole} from "../../model/IUser";
import IAssociationWorking from "../../model/association_working/IAssociationWorking";
import AssociationWorkingDetail from "./AssociationWorkingDetail";
import {AssociationWorkingServiceApi, AssociationWorkingSort} from "../../api/AssociationWorkingServiceApi";
import ContentContainer from "../../components/ContentContainer";

const useStyles = makeStyles(() => createStyles({
    headline: {
        textAlign: "center",
        marginBottom: "40px"
    },
    content: {
        position: "relative"
    },
    newButton: {
        color: "#ffffff",
        backgroundColor: "#02A1A0",
        fontFamily: "Roboto Mono",
        fontSize: "14px",
        lineHeight: "28px",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.8)"
        },
        position: "absolute",
        top: "8px",
        right: "8px",
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: "0"
    }
}));

export default function AssociationWorkings() {
    const [loading, setLoading] = useState<boolean>(false);
    const [associationWorkings, setAssociationWorkings] = useState<IAssociationWorking[]>([]);
    const [newAssociationWorking, setNewAssociationWorking] = useState<boolean>(false);
    const [selectedAssociationWorking, setSelectedAssociationWorking] = useState<string | undefined>(undefined);

    const {user, getToken} = useContext(AuthServiceContext);
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        AssociationWorkingServiceApi.getAssociationWorkings(getToken())
            .then(response => {

                console.info(response);
                const associationWorkings = response.sort(AssociationWorkingSort);
                setAssociationWorkings(associationWorkings);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getToken]);

    const handleCreate = (associationWorking: IAssociationWorking) => {
        setLoading(true);
        AssociationWorkingServiceApi.createAssociationWorking(associationWorking, getToken())
            .then(storedAssociationWorking => {
                console.info("created association working:", associationWorking);
                const newAssociationWorking = [...associationWorkings, storedAssociationWorking];
                setAssociationWorkings(newAssociationWorking);
                setNewAssociationWorking(false);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleUpdate = (associationWorking: IAssociationWorking) => {
        setLoading(true);
        AssociationWorkingServiceApi.updateAssociationWorking(associationWorking, getToken())
            .then((updatedAssociationWorking: IAssociationWorking) => {
                const associationWorkingIndex = associationWorkings
                    .findIndex(associationWorking => associationWorking.number === updatedAssociationWorking.number);
                associationWorkings.splice(associationWorkingIndex, 1, updatedAssociationWorking);
                setAssociationWorkings(associationWorkings);
                setSelectedAssociationWorking(undefined);
                console.info("updated association working:", associationWorking);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleDelete = (associationWorking: IAssociationWorking) => {
        setLoading(true);
        AssociationWorkingServiceApi.deleteAssociationWorking(associationWorking, getToken())
            .then(() => {
                const filteredAssociationWorkings = associationWorkings.filter(function (a) {
                    return a.number !== associationWorking.number;
                })
                setAssociationWorkings(filteredAssociationWorkings);
                setSelectedAssociationWorking(undefined);
                console.info("deleted association working:", associationWorking);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSelection = (selectedId: string) => {
        setSelectedAssociationWorking(selectedId);
    }

    const handleAdd = () => {
        setNewAssociationWorking(true);
    }

    const handleCancel = () => {
        setNewAssociationWorking(false);
    }

    const handleAllowedToCreate = () => {
        return !newAssociationWorking && handleAllowedToEdit();
    }

    const handleAllowedToEdit = () => {
        return user.isInUserRole(UserRole.ADM_ASSOCIATION_WORKING);
    }

    const headerColumns: DataHeaderColumn<IAssociationWorking>[] = [
        {value: "title", sortable: true, node: "Title"},
        {value: "neededPersons", sortable: true, node: "Anzahl Plätze"},
        {value: "meetingDate", sortable: true, node: "Datum"},
        {value: "meetingTime", sortable: false, node: "Uhrzeit"},
        {value: "meetingPlace", sortable: false, node: "Treffpunkt"}
    ];

    const uniqueKeyColumn = "title";

    const dataRows: DataTableRow<IAssociationWorking>[] = associationWorkings.map((associationWorking: IAssociationWorking) => ({
        value: associationWorking,
        nodes: [
            <>{associationWorking.title}</>,
            <>{associationWorking.neededPersons}</>,
            <>{associationWorking.meetingDate}</>,
            <>{associationWorking.meetingTime}</>,
            <>{associationWorking.meetingPlace.name}</>
        ],
        detail:
            <AssociationWorkingDetail associationWorking={associationWorking}
                                      onUpdate={handleUpdate}
                                      onDelete={handleDelete}/>
    }));

    return (
        <ContentContainer process={loading}>
            <h1 className={classes.headline}>Verwaltung der Vereinsarbeiten</h1>
            {newAssociationWorking && <NewAssociationWorking onSave={handleCreate} onCancel={handleCancel}/>}
            <div className={classes.content}>
                {handleAllowedToCreate() && (<ContentButton className={classes.newButton}
                                                            onClick={handleAdd}>Neu</ContentButton>)}
                <DataTable headerColumns={headerColumns}
                           dataRows={dataRows}
                           uniqueKeyColumn={uniqueKeyColumn}
                           selected={selectedAssociationWorking}
                           onSelection={handleSelection}/>
            </div>
        </ContentContainer>
    );
}
