import {handleResponse} from "./ServiceApi";
import IBoat, {IOutOfOrder} from "../model/IBoat";

const { REACT_APP_BOAT_SERVICE_URL } = window._env_;

const BOATS_API = `${REACT_APP_BOAT_SERVICE_URL}/boatmanagement/api/boats`;
const BOATSWAINS_API = `${REACT_APP_BOAT_SERVICE_URL}/boatmanagement/api/boatswains`;

async function buildRequestOptions(method: string, authHeader?: string) {
    return {
        method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader ? (" Bearer " + authHeader) : ""
        }
    };
}

export const BoatServiceApi = {
    getBoats: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${BOATS_API}`, requestOptions).then(handleResponse);
    },
    getBoat: async (number: string, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${BOATS_API}/${number}`, requestOptions).then(handleResponse);
    },
    createBoat: async (boat: IBoat, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(BOATS_API, {
            ...requestOptions,
            body: JSON.stringify(boat)
        }).then(handleResponse);
    },
    updateBoat: async (boat: IBoat, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${BOATS_API}/${boat.number}`, {
            ...requestOptions,
            body: JSON.stringify(boat)
        }).then(handleResponse);
    },
    deleteBoat: async (boat: IBoat, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${BOATS_API}/${boat.number}`, requestOptions).then(handleResponse);
    },
    updateBoatState: async (boat: IBoat, outOfOrder: IOutOfOrder, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${BOATS_API}/${boat.number}/states/default`, {
            ...requestOptions,
            body: JSON.stringify(outOfOrder)
        }).then(handleResponse);
    }
}

export const BoatswainServiceApi = {
    getBoatswains: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${BOATSWAINS_API}`, requestOptions).then(handleResponse);
    }
}
