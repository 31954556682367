import React, {useContext} from "react";
import { Routes, Route, Navigate} from "react-router-dom"
import {AuthServiceContext} from "../provider/AuthServiceProvider";
import * as ROUTE_TARGET from "../constants/routes";
import Homepage from "./Home";
import NotFound from "./NotFound";
import Profile from "./Profile";
import Boats from "./boat/Boats";
import Messages from "./message/Messages";
import Members from "./member/Members";
import LegalNotice from "./footer/LegalNotice";
import DataProtection from "./footer/DataProtection";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import RegistrationData from "./member/RegistrationData";
import AssociationWorkings from "./association_working/AssociationWorkings";
import Groups from "./member/Groups";

export default function Content() {
    const {user} = useContext(AuthServiceContext);
    return (
        <div className="contentPlaceholder">
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                <Routes>
                    <Route path={ROUTE_TARGET.MEMBERS} element={
                        user.authenticated ? <Members/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.NEW_MEMBER_REQUESTS} element={
                        user.authenticated ? <RegistrationData/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.GROUPS} element={
                        user.authenticated ? <Groups/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.BOATS} element={
                        user.authenticated ? <Boats/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.ASSOCIATION_WORKINGS} element={
                        user.authenticated ? <AssociationWorkings/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    <Route path={ROUTE_TARGET.MESSAGES} element={
                        user.authenticated ? <Messages/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    {/* user menu routes */}
                    <Route path={ROUTE_TARGET.PROFILE} element={
                        user.authenticated ? <Profile/> : <Navigate replace to={ROUTE_TARGET.HOME}/>
                    }/>
                    {/* footer link routes */}
                    <Route path={ROUTE_TARGET.LEGAL_NOTICE} element={<LegalNotice/>}/>
                    <Route path={ROUTE_TARGET.DATA_PROTECTION} element={<DataProtection/>}/>
                    {/* default routes */}
                    <Route path={ROUTE_TARGET.HOME} element={<Homepage/>}/>
                    {/* default error routes */}
                    <Route element={<NotFound/>}/>
                </Routes>
            </LocalizationProvider>
        </div>
    );
}
