import React, {createContext, FC, useCallback, useEffect, useMemo, useState} from 'react';
import Keycloak, {KeycloakError} from "keycloak-js";
import IUser, {UserGroup, UserRole} from "../model/IUser";

const { REACT_APP_AUTH_SERVICE_URL, REACT_APP_PUBLIC_URL } = window._env_;

const keycloak = new Keycloak({
    realm: "usco",
    url: `${REACT_APP_AUTH_SERVICE_URL}/auth`,
    clientId: "office-usco"
});

interface AuthService {
    user: IUser;

    login(): void;

    logout(): void;

    getToken(): string;
}

export const AuthServiceContext = createContext<AuthService>(undefined as any);

export const AuthServiceProvider: FC = ({children}) => {
    const [userId, setUserId] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [mailAddress, setMailAddress] = useState<string>("");
    const [firstName, setFirstName] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [roles, setRoles] = useState<UserRole[]>([]);
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [expired, setExpired] = useState<boolean>(false);

    const decodeJWT = (token: string) => {
        const base64UrlToken = token.split('.')[1];
        const base64Token = base64UrlToken.replace('-', '+').replace('_', '/');
        const parsedToken = JSON.parse(window.atob(base64Token));

        setRoles(parsedToken.groups);
        setGroups(parsedToken.usco_roles);
        setUserId(parsedToken.sub);
        setUsername(parsedToken.upn);
        setMailAddress(parsedToken.email);
        setFirstName(parsedToken.given_name);
        setLastName(parsedToken.family_name);
    };

    const handleLogin = useCallback((keycloak: Keycloak.KeycloakInstance) => {
        decodeJWT(keycloak.token!);
        localStorage.setItem("usco-token", keycloak.token!);
        localStorage.setItem("usco-refresh-token", keycloak.refreshToken!);
        localStorage.setItem("usco-id-token", keycloak.idToken!);
        localStorage.setItem("usco-time-skew", String(keycloak.timeSkew!));
    }, []);

    const handleLogout = useCallback(() => {
        setAuthenticated(false);
        setExpired(false);
        localStorage.removeItem("usco-token");
        localStorage.removeItem("usco-refresh-token");
        localStorage.removeItem("usco-id-token");
        localStorage.removeItem("usco-time-skew");
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("usco-token") || undefined;
        const refreshToken = localStorage.getItem("usco-refresh-token") || undefined;
        const idToken = localStorage.getItem("usco-id-token") || undefined;
        const timeSkew = Number(localStorage.getItem("usco-time-skew"));
        keycloak.init({
            token,
            refreshToken,
            idToken,
            timeSkew
        }).then((authenticated: boolean) => {
            setAuthenticated(authenticated);
        }).catch((error: KeycloakError) => {
            console.info("Error during init Keycloak client:", error);
        });
        keycloak.onAuthSuccess = () => {
            console.info(`Login successful for user ID = ${keycloak.subject}!`);
            handleLogin(keycloak);
        }
        keycloak.onAuthError = (error: KeycloakError) => {
            console.info("Login failed!", error);
        }
        keycloak.onAuthLogout = () => {
            handleLogout();
            console.info("Logout successful.");
        }
        keycloak.onTokenExpired = () => {
            console.info(`Token expired for user ID = ${keycloak.subject}!`);
            setExpired(true);
            // refresh token - no needed to handle then() or catch():
            // - because of onAuthRefreshSuccess and onAuthRefreshError
            keycloak.updateToken(0).catch(() => {
                // only observe to prevent log error messages
            });
        }
        keycloak.onAuthRefreshSuccess = () => {
            console.info(`Token refreshed for user ID = ${keycloak.subject}!`);
            handleLogin(keycloak);
            setExpired(false);
        }
        keycloak.onAuthRefreshError = () => {
            console.info("Refreshing Token failed!");
        }
        keycloak.onReady = () => {
            console.info("Keycloak client is Ready!");
        }
    }, [handleLogin, handleLogout]);

    const login = useCallback(() => {
        return keycloak.login().then(() => {
            console.info(keycloak.token);
        });
    }, []);

    const logout = useCallback(() => {
        setAuthenticated(false);
        return keycloak.logout({redirectUri: `${REACT_APP_PUBLIC_URL}`});
    }, []);

    const getToken = useCallback(() => {
        return keycloak.token ? keycloak.token : "";
    }, []);

    const isInRole = useCallback((role: UserRole) => {
        return roles && roles.includes(role);
    }, [roles]);

    const isInGroup = useCallback((group: UserGroup) => {
        return groups && groups.includes(group);
    }, [groups]);

    const user: IUser = useMemo(() => {
        return {
            userId,
            username,
            mailAddress,
            firstName,
            lastName,
            roles,
            groups,
            isInUserRole: isInRole,
            isInUserGroup: isInGroup,
            authenticated,
            expired
        }
    }, [userId, username, mailAddress, firstName, lastName, roles, groups, isInRole, isInGroup, authenticated, expired]);

    const authService: AuthService = useMemo(() => {
        return {
            user,
            login,
            logout,
            getToken
        }
    }, [user, login, logout, getToken]);

    return (
        <AuthServiceContext.Provider value={authService}>
            {children}
        </AuthServiceContext.Provider>
    );
};
