import {createStyles, makeStyles} from "@mui/styles";
import React, {CSSProperties, PropsWithChildren} from "react";

const useStyles = makeStyles(() => createStyles({
    label: {
        fontSize: "16px",
        color: "#023553",
        marginBottom: "20px"
    },
    border: {
        display: "flex",
        flexDirection: "column"
    },
    borderWithLabel: {
    }
}));

interface TextGroupProps extends PropsWithChildren<any> {
    label?: string;
    style?: CSSProperties;
}

export default function TextGroup(props: TextGroupProps) {
    const {label, style} = props;
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.border}`} style={style}>
                {label && (<div className={classes.label}>{label}</div>)}
                {props.children}
            </div>
        </>
    );
}
