import React, {CSSProperties, PropsWithChildren, ReactElement, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Icon,
    IconButton,
    styled
} from "@mui/material";

const DialogActionButton = styled(Button)(() => ({
    color: "#02A1A0",
}))

interface DialogIconButtonProps extends PropsWithChildren<any> {
    icon: string | ReactElement,
    title: string,
    details?: string,
    actionLabel: string,
    onActionClick(): void,
    buttonStyle?: CSSProperties,
    buttonClassName?: string,
    onValidateAction?(): boolean
}

function DialogIconButton(props: DialogIconButtonProps) {
    const {icon, title, details, actionLabel, onActionClick, buttonStyle, buttonClassName,
        onValidateAction, children} = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleAction = () => {
        onActionClick();
        handleClose();
    }

    return (
        <>
            <IconButton style={buttonStyle}
                        className={buttonClassName}
                        onClick={handleClickOpen}><Icon>{icon}</Icon></IconButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-button-title">
                <DialogTitle id="dialog-button-title">{title}</DialogTitle>
                <DialogContent style={{paddingBottom: `${children ? "0" : "20px"}`}}>
                    {details && (<DialogContentText style={{ color: "#000000"}}>{details}</DialogContentText>)}
                    {children}
                </DialogContent>
                <DialogActions>
                    <DialogActionButton onClick={handleClose}>Abbrechen</DialogActionButton>
                    <DialogActionButton disabled={onValidateAction ? !onValidateAction() : false}
                                        onClick={handleAction}>{actionLabel}</DialogActionButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default (DialogIconButton);
