import IUser, {UserGroup} from "../model/IUser";
import {handleResponse} from "./ServiceApi";
import IMember, {IMemberBankAccount, IMemberIdentifier} from "../model/member/IMember";
import IRegistrationData from "../model/member/IRegistrationData";

const { REACT_APP_MEMBER_SERVICE_URL } = window._env_;

const MEMBER_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/members`;
const GROUP_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/groups`;
const FAMILY_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/families`;
const CONTACT_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/contacts`;
const REGISTER_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/registrations`;
const USER_API = `${REACT_APP_MEMBER_SERVICE_URL}/membermanagement/api/users`;

async function buildRequestOptions(method: string, authHeader?: string): Promise<RequestInit> {
    return {
        method,
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader ? (" Bearer " + authHeader) : ""
        }
    };
}

export const MemberServiceApi = {
    getMembers: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${MEMBER_API}`, requestOptions).then(handleResponse);
    },
    getMember: async (user: IUser, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${MEMBER_API}/${user.userId}`, requestOptions).then(handleResponse);
    },
    createMember: async (username: string, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${MEMBER_API}`, {
            ...requestOptions,
            body: username
        }).then(handleResponse);
    },
    updateMember: async (member: IMember, token: string) => {
        console.info("update member: ", member);
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${MEMBER_API}/${member.identifier}`, {
            ...requestOptions,
            body: JSON.stringify(member)
        }).then(handleResponse);
    },
    getGroupsOfMember: async (member: IMember, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${MEMBER_API}/${member.identifier}/groups`, requestOptions).then(handleResponse);
    },
    updateBankAccount: async (member: IMember, bankAccount: IMemberBankAccount, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${MEMBER_API}/${member.identifier}/bank-accounts/default`, {
            ...requestOptions,
            body: JSON.stringify(bankAccount)
        }).then(handleResponse);
    },
    updateState: async (member: IMember, state: string, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${MEMBER_API}/${member.identifier}/states/default`, {
            ...requestOptions,
            body: JSON.stringify({ state: state })
        }).then(handleResponse);
    },
    deleteMember: async (member: IMember, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${MEMBER_API}/${member.identifier}`, requestOptions).then(handleResponse);
    }
}

export const FamilyServiceApi = {
    getFamilyOfMember: async (member: IMember, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${FAMILY_API}/${member.identifier}/subordinates`, requestOptions).then(handleResponse);
    },
    createFamily: async (principleMember: IMember, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${FAMILY_API}`, {
            ...requestOptions,
            body: JSON.stringify(principleMember)
        }).then(handleResponse);
    },
    deleteFamily: async (principleMember: IMember, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${FAMILY_API}/${principleMember.identifier}`, requestOptions).then(handleResponse);
    },
    addMemberToFamily: async (principleMember: IMember,
                              subordinateIdentifier: IMemberIdentifier, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${FAMILY_API}/${principleMember.identifier}/subordinates`, {
            ...requestOptions,
            body: JSON.stringify({identifier: subordinateIdentifier.identifier})
        }).then(handleResponse);
    },
    removeMemberFromFamily: async (principleMember: IMember,
                                   subordinateIdentifier: IMemberIdentifier, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${FAMILY_API}/${principleMember.identifier}/subordinates/${subordinateIdentifier.identifier}`, requestOptions)
            .then(handleResponse);
    },
}

export const GroupServiceApi = {
    getMembersOfGroup: async (group: UserGroup, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${GROUP_API}/${group}/members`, requestOptions).then(handleResponse);
    },
    addMemberToGroup: async (group: UserGroup, memberIdentifier: IMemberIdentifier, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${GROUP_API}/${group}/members`, {
            ...requestOptions,
            body: JSON.stringify(memberIdentifier)
        }).then(handleResponse);
    },
    removeMemberFromGroup: async (group: UserGroup, memberIdentifier: IMemberIdentifier, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${GROUP_API}/${group}/members/${memberIdentifier.identifier}`, requestOptions)
            .then(handleResponse);
    },
}

export const RegisterServiceApi = {
    getRegistrationData: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${REGISTER_API}`, requestOptions)
            .then(handleResponse);
    },
    updateRegistrationData: async (registrationData: IRegistrationData, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${REGISTER_API}/${registrationData.username}`, {
            ...requestOptions,
            body: JSON.stringify(registrationData)
        }).then(handleResponse);
    },
    deleteRegistrationData: async (registrationData: IRegistrationData, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${REGISTER_API}/${registrationData.username}`, requestOptions)
            .then(handleResponse);
    },
    resendVerifyMailAddress: async (registrationData: IRegistrationData, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${REGISTER_API}/${registrationData.username}/notifications`, requestOptions)
            .then(handleResponse)
    },
    sendRegister: async (registrationData: IRegistrationData) => {
        const requestOptions = await buildRequestOptions("POST");
        return fetch(`${REGISTER_API}`, {
            ...requestOptions,
            body: JSON.stringify(registrationData)
        }).then(handleResponse);
    },
    checkUser: async (username: string, mailAddress: string) => {
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${USER_API}?username=${escape(username)}&mailAddress=${escape(mailAddress)}`,
            requestOptions).then(handleResponse);
    }
}

export const ContactServiceApi = {
    getContacts: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${CONTACT_API}/`, requestOptions).then(handleResponse);
    },
    getContact: async (memberIdentifier: string, token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${CONTACT_API}/${memberIdentifier}`, requestOptions).then(handleResponse);
    }
}
