import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import TextGroup from "../../components/TextGroup";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import Text from "../../components/Text";
import {IMemberIdentifier} from "../../model/member/IMember";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {roleToText, UserRole} from "../../model/IUser";
import {ContactServiceApi, GroupServiceApi} from "../../api/MemberServiceApi";
import {IContact} from "../../model/member/IContact";
import {OptionType} from "../../components/BackgroundAutocomplete";
import {IGroup} from "../../model/member/IGroup";
import GroupAddMember from "./GroupAddMember";
import DialogIconButton from "../../components/DialogIconButton";

const useStyles = makeStyles(() => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "#E8E7E8"
    },
    oneColumn: {
        width: "100%"
    },
    twoColumn: {
        width: "calc(66%)"
    },
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    middleColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "auto",
        marginRight: "auto"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
        marginRight: "0"
    },
    button: {
        marginRight: "10px"
    },
    lastButton: {
        marginRight: "0"
    },
    participantDeleteButton: {
        marginLeft: "5px",
        marginTop: "8px",
        height: "40px"
    },
}));

interface GroupDetailProps extends PropsWithChildren<any> {
    group: IGroup;
    index: number
}

export default function GroupDetail(props: GroupDetailProps) {
    const {group, index} = props;
    const classes = useStyles();
    const {user, getToken} = useContext(AuthServiceContext);


    // contact model
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [optionTypes, setOptionTypes] = useState<OptionType[]>([]);
    // members of group model
    const [groupMembers, setGroupMembers] = useState<IMemberIdentifier[]>([]);

    useEffect(() => {
        const fetchContacts = async (): Promise<void> => {
            const contacts: IContact[] = await ContactServiceApi.getContacts(getToken());
            setContacts(contacts);
            const groupMembers: IMemberIdentifier[] = await GroupServiceApi.getMembersOfGroup(group.type, getToken());
            setGroupMembers(groupMembers);
        }
        fetchContacts()
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            });
    }, [group, getToken]);

    useEffect(() => {
        const optionTypes = contacts.map(contact => {
            return {title: contact.firstName + " " + contact.lastName + " (" + contact.mailAddress + ")", value: contact.identifier}
        });
        setOptionTypes(optionTypes);
    }, [contacts]);

    const handleAllowedToEdit = () => {
        return user.isInUserRole(UserRole.ADM_USER);
    }

    const onAddToGroup = (groupMemberToAdd: string) => {
        if (groupMemberToAdd !== "") {
            GroupServiceApi.addMemberToGroup(group.type, {identifier: groupMemberToAdd}, getToken())
                .then((response) => {
                    console.info("add", response);
                    groupMembers.push(response);
                    setGroupMembers([...groupMembers]);
                })
                .catch((error) => {
                    console.error("unexpected error: " + error.message);
                });
        }
    }

    const onDeleteFromGroup = (groupMember: IMemberIdentifier) => {
        GroupServiceApi.removeMemberFromGroup(group.type, groupMember, getToken())
            .then(() => {
                console.info("delete", groupMember);
                const groupMemberIndex = groupMembers.findIndex(data => data.identifier === groupMember.identifier);
                groupMembers.splice(groupMemberIndex, 1);
                setGroupMembers([...groupMembers]);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            });
    }

    const convertToParticipantContact = (groupMember: IMemberIdentifier) => {
        let participantContact: IContact | undefined = undefined;

        const contactIndex = contacts.findIndex(contact => contact.identifier === groupMember.identifier);
        if (contactIndex > -1) {
            participantContact = contacts[contactIndex];
        }

        return participantContact ? participantContact.firstName + " " + participantContact.lastName + " (" + participantContact.mailAddress + ")" : "";
    }

    return (
        <div key={index} className={classes.container}>
            <TextGroup style={{paddingBottom: "20px"}} label="Befugnisse dieser Gruppe und Nutzer hinzufügen">
                <TextRow rowStyle={{paddingBottom: "20px"}}>{group.description}</TextRow>
                <TextRow rowStyle={{textDecoration: "underline"}}>Rechte der Nutzergruppe:</TextRow>
                {group.rights.map((value: UserRole, index: number) => (
                    <TextRow key={index}
                             last={index + 1 === group.rights.length}
                             rowStyle={{paddingBottom: `${index + 1 === group.rights.length ? "20px" : "0"}`}}>
                        - {roleToText(value)}
                    </TextRow>))}
                <GroupAddMember groupMembers={groupMembers}
                                optionTypes={optionTypes}
                                onAddMemberToGroup={onAddToGroup}/>
            </TextGroup>
            <TextGroup label="Aktuelle Nutzer dieser Gruppe">
                {groupMembers.map((groupMember: IMemberIdentifier, index: number) => (
                    <TextRow key={index}>
                        <Text className={classes.twoColumn}
                              label="Nutzer"
                              value={
                                  convertToParticipantContact(groupMember)
                              }/>
                        {handleAllowedToEdit() && (<DialogIconButton
                                icon="delete"
                                title="Nutzer wirklich aus Gruppe entfernen?"
                                details="Hinweis: Durch das Entfernen wird dem Nutzer umgehend die Berechtigung entzogen."
                                actionLabel="Entfernen"
                                onActionClick={() => {
                                    onDeleteFromGroup(groupMember);
                                }}
                                buttonClassName={classes.participantDeleteButton}/>)}
                    </TextRow>
                ))}
            </TextGroup>
        </div>
    );
}
