import {Paper} from "@mui/material";
import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import ContentButton from "../../components/ContentButton";
import {StyledTextField} from "../../components/StyledComponents";
import IAssociationWorking from "../../model/association_working/IAssociationWorking";
import BackgroundTextArea from "../../components/BackgroundTextArea";
import TextGroup from "../../components/TextGroup";
import BackgroundAutocomplete, {OptionType} from "../../components/BackgroundAutocomplete";
import {ContactServiceApi} from "../../api/MemberServiceApi";
import {IContact} from "../../model/member/IContact";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {DateValidationError} from "@mui/lab/internal/pickers/date-utils";
import {ParseableDate} from "@mui/lab/internal/pickers/constants/prop-types";
import {DatePicker} from "@mui/lab";
import {handleDateToIsoString} from "../../model/DateHandler";

const useStyles = makeStyles(() => createStyles({
    oneColumn: {
        width: "100%"
    },
    twoColumn: {
        width: "calc(66%)",
        marginRight: "10px"
    },
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    middleColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "auto",
        marginRight: "auto"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginLeft: "auto"
    },
    button: {
        marginRight: "10px"
    },
    lastButton: {
        marginRight: "0"
    },
    card: {
        marginTop: "0.75rem",
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        backgroundColor: "rgb(232, 231, 232)"
    },
    header: {
        fontSize: "16px",
        color: "#023553",
        marginBottom: "20px"
    }
}));

interface NewAssociationWorkingProps extends PropsWithChildren<any> {
    onSave(newAssociationWorking: IAssociationWorking): void;

    onCancel(): void;
}

export default function NewAssociationWorking(props: NewAssociationWorkingProps) {

    const {onSave, onCancel} = props;
    const {getToken} = useContext(AuthServiceContext);
    const classes = useStyles();

    // model
    const [title, setTitle] = useState<string>();
    const [expireDate, setExpireDate] = useState<Date | null>(null);
    const [description, setDescription] = useState<string>();
    const [personInCharge, setPersonInCharge] = useState<string>();

    const [meetingDate, setMeetingDate] = useState<string>();
    const [meetingTime, setMeetingTime] = useState<string>();
    const [meetingPlaceName, setMeetingPlaceName] = useState<string>();
    const [meetingPlaceLatitude, setMeetingPlaceLatitude] = useState<string>();
    const [meetingPlaceLongitude, setMeetingPlaceLongitude] = useState<string>();

    const [neededPersons, setNeededPersons] = useState<number>();
    const [neededTrailerCouplings, setNeededTrailerCouplings] = useState<number>();

    // dropdown content
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [optionTypes, setOptionTypes] = useState<OptionType[]>([]);
    const [validated] = useState<boolean>(false);

    useEffect(() => {
        ContactServiceApi.getContacts(getToken())
            .then((contacts: IContact[]) => {
                console.info("all contacts:", contacts);
                setContacts(contacts);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
                return false;
            });
    }, [getToken]);

    useEffect(() => {
        const optionTypes = contacts.map(contact => {
            return { title: contact.firstName + " " + contact.lastName + " (" + contact.mailAddress + ")", value: contact.identifier}
        });
        setOptionTypes(optionTypes);
    }, [contacts]);

    const handleSave = () => {
        onSave({
            number: "",
            title: title ? title : "",
            description: description ? description : "",
            meetingDate: meetingDate ? meetingDate : "",
            meetingTime: meetingTime ? meetingTime : "",
            meetingPlace: {
                name: meetingPlaceName ? meetingPlaceName : "",
                latitude: meetingPlaceLatitude,
                longitude: meetingPlaceLongitude
            },
            personInCharge: personInCharge ? personInCharge : "",
            neededPersons: neededPersons ? neededPersons : 0,
            neededTrailerCouplings: neededTrailerCouplings ? neededTrailerCouplings : 0,
            expireDate: handleDateToIsoString(expireDate),
            participants: []
        })
    }

    const handleCancel = () => {
        onCancel();
    }

    return (<>

            <Paper elevation={3} className={classes.card}>
                <TextGroup label="Neue Vereinsarbeit anlegen">
                    <TextRow>
                        <StyledTextField className={classes.twoColumn}
                                         required
                                         error={validated && (!title || title.length === 0)}
                                         helperText={validated && (!title || title.length === 0) ? "Der Titel fehlt." : null}
                                         variant="outlined"
                                         label="Titel"
                                         placeholder="Der Titel der Vereinsarbeit"
                                         onChange={(event: any) => {
                                             setTitle(event.target.value);
                                         }}/>
                        <DatePicker
                            label="Anzeigen bis"
                            openTo="year"
                            views={["year", "day"]}
                            value={expireDate}
                            onChange={setExpireDate}
                            onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                                console.info(reason, value);
                            }}
                            renderInput={(params) =>
                                <StyledTextField {...params}
                                                 className={classes.rightColumn}
                                                 required
                                                 label="Anzeigen bis"
                                                 placeholder="Anzeigen bis"
                                />}
                            inputFormat="dd.MM.yyyy"
                            mask="__.__.____"
                        />
                    </TextRow>
                    <TextRow>
                        <BackgroundTextArea className={classes.oneColumn}
                                            required
                                            error={validated && (!description || description.length === 0)}
                                            helperText={validated && (!description || description.length === 0) ? "Der Beschreibung fehlt." : null}
                                            rows={5}
                                            variant="outlined"
                                            label="Beschreibung"
                                            placeholder="Die Beschreibung der Vereinsarbeit"
                                            onChange={(event: any) => {
                                                setDescription(event.target.value);
                                            }}/>
                    </TextRow>
                    <TextRow>
                        <BackgroundAutocomplete id="how-is-select-input"
                                                className={classes.twoColumn}
                                                label="Verantwortliche"
                                                selectedContactIdentifier=""
                                                optionTypes={optionTypes}
                                                onChange={(newValue: string) => {
                                                    setPersonInCharge(newValue);
                                                }}/>
                    </TextRow>
                </TextGroup>
                <TextGroup label="Wann und Wo">
                    <TextRow>
                        <StyledTextField className={classes.leftColumn}
                                         required
                                         error={validated && (!meetingDate || meetingDate.length === 0)}
                                         helperText={validated && (!meetingDate || meetingDate.length === 0) ? "Der Datum fehlt." : null}
                                         variant="outlined"
                                         label="Datum"
                                         placeholder="Das Datum der Vereinsarbeit"
                                         onChange={(event: any) => {
                                             setMeetingDate(event.target.value);
                                         }}/>
                    </TextRow>
                    <TextRow>
                        <StyledTextField className={classes.leftColumn}
                                         required
                                         error={validated && (!meetingTime || meetingTime.length === 0)}
                                         helperText={validated && (!meetingTime || meetingTime.length === 0) ? "Der Uhrzeit fehlt." : null}
                                         variant="outlined"
                                         label="Uhrzeit"
                                         placeholder="Das Uhrzeit der Vereinsarbeit"
                                         onChange={(event: any) => {
                                             setMeetingTime(event.target.value);
                                         }}/>
                    </TextRow>
                    <TextRow>
                        <StyledTextField className={classes.leftColumn}
                                         required
                                         error={validated && (!meetingPlaceName || meetingPlaceName.length === 0)}
                                         helperText={validated && (!meetingPlaceName || meetingPlaceName.length === 0) ? "Der Treffpunkt fehlt." : null}
                                         variant="outlined"
                                         label="Treffpunkt"
                                         placeholder="Das Treffpunkt der Vereinsarbeit"
                                         onChange={(event: any) => {
                                             setMeetingPlaceName(event.target.value);
                                         }}/>
                        <StyledTextField className={classes.middleColumn}
                                         error={validated && (!meetingPlaceLatitude || meetingPlaceLatitude.length === 0)}
                                         helperText={validated && (!meetingPlaceLatitude || meetingPlaceLatitude.length === 0) ? "Der Längengrad (lat) fehlt." : null}
                                         variant="outlined"
                                         label="Längengrad (lat)"
                                         placeholder="Das Längengrad (lat) der Vereinsarbeit"
                                         onChange={(event: any) => {
                                             setMeetingPlaceLatitude(event.target.value);
                                         }}/>
                        <StyledTextField className={classes.rightColumn}
                                         error={validated && (!meetingPlaceLongitude || meetingPlaceLongitude.length === 0)}
                                         helperText={validated && (!meetingPlaceLongitude || meetingPlaceLongitude.length === 0) ? "Der Breitengrad (long) fehlt." : null}
                                         variant="outlined"
                                         label="Breitengrad (long)"
                                         placeholder="Das Breitengrad (long) der Vereinsarbeit"
                                         onChange={(event: any) => {
                                             setMeetingPlaceLongitude(event.target.value);
                                         }}/>
                    </TextRow>
                </TextGroup>
                <TextGroup label="Wer kann sich anmelden">
                    <TextRow>
                        <StyledTextField className={classes.leftColumn}
                                         required
                                         error={validated && (!neededPersons)}
                                         helperText={validated && (!neededPersons) ? "Der Anzahl Plätze fehlt." : null}
                                         variant="outlined"
                                         label="Anzahl Plätze"
                                         placeholder="Das Anzahl Plätze der Vereinsarbeit"
                                         onChange={(event: any) => {
                                             setNeededPersons(event.target.value);
                                         }}/>
                        <StyledTextField className={classes.rightColumn}
                                         required
                                         error={validated && (!neededTrailerCouplings)}
                                         helperText={validated && (!neededTrailerCouplings) ? "Der Anzahl benötigter AHK fehlt." : null}
                                         variant="outlined"
                                         label="Anzahl benötigter AHK"
                                         placeholder="Das Anzahl benötigter AHK der Vereinsarbeit"
                                         onChange={(event: any) => {
                                             setNeededTrailerCouplings(event.target.value);
                                         }}/>
                    </TextRow>
                </TextGroup>
                <div className={classes.buttonContainer}>
                    <ContentButton className={classes.button}
                                   variant="secondary"
                                   onClick={handleCancel}>Abbrechen</ContentButton>
                    <ContentButton className={classes.lastButton}
                                   onClick={handleSave}>Speichern</ContentButton>
                </div>
            </Paper></>
    );
}
