import {MenuItem, Paper} from "@mui/material";
import IBoat, {Location, locationText} from "../../model/IBoat";
import React, {PropsWithChildren, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import ContentButton from "../../components/ContentButton";
import {StyledTextField} from "../../components/StyledComponents";
import BackgroundDropdown from "../../components/BackgroundDropdown";

const useStyles = makeStyles(() => createStyles({
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    middleColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "auto",
        marginRight: "auto"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginLeft: "auto"
    },
    button: {
        marginRight: "10px"
    },
    lastButton: {
        marginRight: "0"
    },
    card: {
        marginTop: "0.75rem",
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        backgroundColor: "rgb(232, 231, 232)"
    },
    header: {
        fontSize: "16px",
        color: "#023553",
        marginBottom: "20px"
    }
}));

interface NewBoatProps extends PropsWithChildren<any> {
    onSave(newBoat: IBoat): void;

    onCancel(): void;
}

export default function NewBoat(props: NewBoatProps) {

    const {onSave, onCancel} = props;

    const classes = useStyles();

    const [boatName, setBoatName] = useState<string>();
    const [boatType, setBoatType] = useState<string>();
    const [boatLocation, setBoatLocation] = useState<Location>(Location.NONE);

    const [validated, setValidated] = useState<boolean>(false);

    const handleSave = () => {
        if (handleIsValidate()) {
            setValidated(false);
            console.info("save");
            onSave({
                number: "",
                name: boatName ? boatName : "",
                type: boatType ? boatType : "",
                location: boatLocation ? boatLocation : Location.NONE,
                boatswains: []
            });
        } else {
            setValidated(true);
            console.info("boat to save not valid");
        }
    }

    const handleCancel = () => {
        onCancel();
    }

    const handleIsValidate = (): boolean => {
        return (!!boatName && boatName.length > 0)
            && (!!boatType && boatType.length > 0)
            && (boatLocation !== Location.NONE);
    }

    return (<>

            <Paper elevation={3} className={classes.card}>
                <div className={classes.header}>Neues Boot anlegen</div>
                <TextRow>
                    <StyledTextField className={classes.leftColumn}
                                     required
                                     error={validated && (!boatName || boatName.length === 0)}
                                     helperText={validated && (!boatName || boatName.length === 0) ? "Der Bootsname fehlt." : null}
                                     variant="outlined"
                                     label="Bootsname"
                                     placeholder="Der Name des Boots"
                                     onChange={(event: any) => {
                                             setBoatName(event.target.value);
                                         }}/>
                    <StyledTextField className={classes.middleColumn}
                                     required
                                     error={validated && (!boatType || boatType.length === 0)}
                                     helperText={validated && (!boatType || boatType.length === 0) ? "Der Bootstyp fehlt." : null}
                                     variant="outlined"
                                     label="Bootstyp"
                                     placeholder="Der Bootstyp"
                                     onChange={(event: any) => {
                                             setBoatType(event.target.value);
                                         }}/>
                    <BackgroundDropdown id="boat-new-location-label"
                                        label="Liegeplatz"
                                        className={classes.rightColumn}
                                        style={{marginBottom: "17px"}}
                                        useValue={true}
                                        value={boatLocation}
                                        nonValue={Location.NONE}
                                        errorMessage="Liegeplatz nicht ausgewählt."
                                        validated={validated}
                                        required={true}
                                        onChange={(event: any) => {
                                            setBoatLocation(event.target.value);
                                        }}>
                        <MenuItem value={Location.NONE} disabled>{locationText(Location.NONE)}</MenuItem>
                        <MenuItem value={Location.BORNHORST}>{locationText(Location.BORNHORST)}</MenuItem>
                        <MenuItem value={Location.BUENTING}>{locationText(Location.BUENTING)}</MenuItem>
                        <MenuItem value={Location.EYHAUSEN}>{locationText(Location.EYHAUSEN)}</MenuItem>
                        <MenuItem value={Location.OELTJEN}>{locationText(Location.OELTJEN)}</MenuItem>
                    </BackgroundDropdown>
                </TextRow>
                <div className={classes.buttonContainer}>
                    <ContentButton className={classes.button}
                                   variant="secondary"
                                   onClick={handleCancel}>Abbrechen</ContentButton>
                    <ContentButton className={classes.lastButton}
                                   onClick={handleSave}>Speichern</ContentButton>
                </div>
            </Paper></>
    );
}
