import React from "react";
import HeaderImage from "./header/HeaderImage";
import HeaderMenu from "./header/HeaderMenu";
import '../App.css';

export default function Header() {

    return (
        <header className="headerPlaceholder">
            <HeaderImage/>
            <HeaderMenu/>
        </header>
    );
}
