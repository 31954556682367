import React, {PropsWithChildren, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import {Icon, IconButton} from "@mui/material";
import BackgroundAutocomplete, {OptionType} from "../../components/BackgroundAutocomplete";
import {IMemberIdentifier} from "../../model/member/IMember";
import ContentButton from "../../components/ContentButton";
import DialogIconButton from "../../components/DialogIconButton";

const useStyles = makeStyles(() => createStyles({
    twoColumn: {
        width: "calc(66%)"
    },
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    participantCancelButton: {
        marginLeft: "5px",
        marginTop: "8px",
        height: "40px"
    },
    participantAddButton: {
        fontSize: "16px",
        marginLeft: "5px",
        marginTop: "auto",
        marginBottom: "auto"
    },
}));

interface GroupAddMemberProps extends PropsWithChildren<any> {
    groupMembers: IMemberIdentifier[];
    optionTypes: OptionType[];

    onAddMemberToGroup(memberIdentifier: string): void;
}

export default function GroupAddMember(props: GroupAddMemberProps) {
    const {groupMembers, optionTypes, onAddMemberToGroup} = props;
    const classes = useStyles();

    // model
    const [groupMemberToAdd, setGroupMemberToAdd] = useState<string>("");
    const [openAddMember, setOpenAddMember] = useState<boolean>(false);

    const handleAddMember = () => {
        onAddMemberToGroup(groupMemberToAdd);
        setGroupMemberToAdd("");
        setOpenAddMember(false);
    }

    const handleCancelMember = () => {
        setGroupMemberToAdd("");
        setOpenAddMember(false);
    }

    return (
        <>
            {openAddMember && (
                <TextRow rowStyle={{paddingBottom: "20px", fontWeight: "bold"}}>Weitere Nutzer zur Gruppe hinzufügen?</TextRow>
            )}
            <TextRow>
                {(openAddMember) && (<>
                    <BackgroundAutocomplete id="AddMemberInput"
                                            className={classes.twoColumn}
                                            label="Mitglied wählen"
                                            selectedContactIdentifier={""}
                                            optionTypes={optionTypes.filter(optionType => !groupMembers.map(value => value.identifier).includes(optionType.value))}
                                            onChange={(newValue: string) => {
                                                setGroupMemberToAdd(newValue);
                                            }}/>
                    <DialogIconButton icon="done"
                                      title="Nutzer wirklich zur Gruppe hinzufügen?"
                                      details="Hinweis: Durch das Hinzufügen wird der Nutzer umgehend berechtigt."
                                      actionLabel="Hinzufügen"
                                      onActionClick={() => {
                                          handleAddMember();
                                      }}
                                      buttonClassName={classes.participantCancelButton}/>
                    <IconButton className={classes.participantCancelButton}
                                onClick={() => {
                                    handleCancelMember();
                                }}>
                        <Icon>clear</Icon>
                    </IconButton>
                </>)}
                {(!openAddMember) && (<>
                    <ContentButton style={{margin: "0"}}
                                   variant="secondary"
                                   disabled={optionTypes.length === groupMembers.length}
                                   onClick={() => {
                                       setOpenAddMember(true);
                                   }}>Weiteren Nutzer hinzufügen</ContentButton>
                </>)}
            </TextRow>
        </>
    );
}
