import React from "react";
import {Theme} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dataProtectionContainer: {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% - 40px)",
        maxWidth: "1040px",
        padding: "0 20px 20px",
        margin: "0 auto auto"
    },
    dataProtectionHeadline: {
        width: "100%",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "52px",
        textAlign: "center",
        color: "#023553",
        margin: "40px auto",
        [theme.breakpoints.down(950)]: {
            fontSize: "30px",
            lineHeight: "40px"
        },
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px"
        }
    },
}));

export default function DataProtection() {

    const classes = useStyles();

    return (
        <div className={classes.dataProtectionContainer}>
            <div className={classes.dataProtectionHeadline}>Datenschutzerklärung</div>
            <p>Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen
                der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und
                nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen
                Leistungen, verarbeitet.</p>
            <p>Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung
                (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe
                automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im
                Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation,
                das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen,
                die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
                Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder
                die Vernichtung.</p>
            <p>Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art,
                Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit
                wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
                entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken
                sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch
                Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>
            <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
            <ul style={{listStyleType: "none"}}>
                <li><p>I. Informationen über uns als Verantwortliche</p></li>
                <li><p>II. Rechte der Nutzer und Betroffenen</p></li>
                <li><p>III. Informationen zur Datenverarbeitung</p></li>
            </ul>
            <h2 style={{color: "rgb(2, 53, 83)"}}>I. Informationen über uns als Verantwortliche</h2>
            <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
            <p>Universitätssegelclub Oldenburg e.V.<br/>
                Thomas Müller<br/>
                Rauhehorst 193<br/>
                26127 Oldenburg<br/>
                E-Mail: info@usco-online.de</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>II. Rechte der Nutzer und Betroffenen</h2>
            <p>Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer
                und Betroffenen das Recht</p>
            <ul>
                <li><p>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über
                    die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie
                    auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</p>
                </li>
                <li><p>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten
                    (vgl. auch Art. 16 DSGVO);</p>
                </li>
                <li><p>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO),
                    oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO
                    erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</p>
                </li>
                <li><p>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf
                    Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art.
                    20 DSGVO);</p>
                </li>
                <li><p>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass
                    die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche
                    Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</p>
                </li>
            </ul>
            <p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten
                durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von
                Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18
                DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese
                Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet
                dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.
            </p>
            <p style={{fontWeight: 500}}>
                Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch
                gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den
                Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist
                ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.
            </p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>III. Informationen zur Datenverarbeitung</h2>
            <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder
                gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine
                gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden
                Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.
            </p>
            <h3 style={{color: "rgb(2, 53, 83)"}}>Serverdaten</h3>
            <p>Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen
                Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren
                Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und
                Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf
                unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres
                Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die
                IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts
                erfolgt, erhoben.</p>
            <p>Diese so erhobenen Daten werden vorübergehend gespeichert, dies jedoch nicht gemeinsam mit
                anderen Daten von Ihnen.</p>
            <p>Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser
                berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit
                unseres Internetauftritts.</p>
            <p>Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung
                zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung
                eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.</p>
            <h3 style={{color: "rgb(2, 53, 83)"}}>Cookies</h3>
            <p><span style={{color: "rgb(2, 53, 83)", fontWeight: 500}}>a) Sitzungs-Cookies/Session-Cookies</span><br/>
                Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder
                andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem
                Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang
                bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder
                Ihre IP-Adresse, verarbeitet.</p>
            <p>Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und
                sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen
                Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.</p>
            <p>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten
                zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>
            <p>Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser
                berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts.
                Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.</p>
            <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
            <p><span style={{color: "rgb(2, 53, 83)", fontWeight: 500}}>b) Drittanbieter-Cookies</span><br/>
                Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit
                denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres
                Internetauftritts zusammenarbeiten, verwendet.</p>
            <p>Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung
                solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>
            <p><span style={{color: "rgb(2, 53, 83)", fontWeight: 500}}>c) Beseitigungsmöglichkeit</span><br/>
                Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers
                verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit
                löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret
                genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder
                Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support.
                Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des
                Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres
                Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von Ihrem
                konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion
                oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.</p>
            <p>Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu
                führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.</p>
            <h3 style={{color: "rgb(2, 53, 83)"}}>Nutzung der Vereinsleistungen</h3>
            <p>Die von Ihnen zur Inanspruchnahme unserer Vereinsleistungen und der Services im
                Mitgliederbereich unserer Website übermittelten Daten werden von uns zum Zwecke der
                Abwicklung der Vereinsmitgliedschaft verarbeitet und sind insoweit erforderlich. Das
                Zustandekommen der Vereinsmitgliedschaft und die Mitgliedschaft selbst sind ohne
                Bereitstellung Ihrer Daten nicht möglich.</p>
            <p>Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
            <p>Wir löschen die Daten nach Beendigung der Vereinsmitgliedschaft, müssen dabei aber die
                rechtlichen und steuerrechtlichen Aufbewahrungsfristen beachten.</p>
            <p>Im Rahmen der Mitgliedschaft geben wir Ihre Daten an den Finanzdienstleister weiter,
                soweit die Weitergabe Ihrer Daten zu Bezahlzwecken erforderlich ist, z. B. zum Bankeinzug des
                Mitgliedsbeitrags oder zur Aus-/Rückzahlung von Vergütungen oder Auslagen.</p>
            <p>Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6 Abs. 1 lit. b) DSGVO.</p>
            <h3 style={{color: "rgb(2, 53, 83)"}}>Mitgliedskonto/Registrierungsfunktion</h3>
            <p>Falls Sie über unseren Internetauftritt ein Mitgliedskonto bei uns anlegen, werden wir die
                von Ihnen bei der Registrierung eingegebenen Daten (also bspw. Ihren Namen, Ihre Anschrift
                oder Ihre E-Mail-Adresse) ausschließlich für das Zustandekommen der Mitgliedschaft, für die
                Nutzung und Bereitstellungen der Leistungen im Zusammenhang mit der Mitgliedschaft oder zum
                Zwecke der Mitgliederpflege (bspw. um Ihnen eine Übersicht über die ehrenamtlichen Tätigkeiten
                im Verein zu bieten) erheben und speichern. Gleichzeitig speichern wir dann die IP-Adresse und
                das Datum Ihrer Registrierung nebst Uhrzeit. Eine Weitergabe dieser Daten an Dritte erfolgt
                natürlich nicht.</p>
            <p>Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in diese Verarbeitung eingeholt
                und auf diese Datenschutzerklärung verwiesen. Die dabei von uns erhobenen Daten werden
                ausschließlich für die Zurverfügungstellung des Migliedskontos verwendet.</p>
            <p>Soweit Sie in diese Verarbeitung einwilligen, ist Art. 6 Abs. 1 lit. a) DSGVO Rechtsgrundlage
                für die Verarbeitung.</p>
            <p>Sofern die Eröffnung des Kundenkontos zusätzlich auch dem Zustandekommen der Mitgliedschaft
                oder den Leistungen der Mitgliedschaft dient, so ist Rechtsgrundlage für diese Verarbeitung
                auch noch Art. 6 Abs. 1 lit. b) DSGVO.</p>
            <p>Die uns erteilte Einwilligung in die Eröffnung und den Unterhalt des Mitgliedskontos können
                Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu
                müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen. Damit geht die Beendigung
                der Vereinsmitgliedschaft einher, da die wesentlichen Leistungen unseres Vereins nur im
                Zusammenhang mit dem Mitgliedskonto genutzt werden können.</p>
            <p>Die insoweit erhobenen Daten werden gelöscht, sobald die Verarbeitung nicht mehr erforderlich
                ist. Hierbei müssen wir aber rechtliche und steuerrechtliche Aufbewahrungsfristen beachten.</p>
            <h3 style={{color: "rgb(2, 53, 83)"}}>Kontaktanfragen / Kontaktmöglichkeit</h3>
            <p>Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen
                angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung
                und Beantwortung Ihre Anfrage erforderlich – ohne deren Bereitstellung können wir Ihre Anfrage
                nicht oder allenfalls eingeschränkt beantworten.</p>
            <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
            <p>Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der
                Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich
                etwaig anschließenden Vertragsabwicklung.</p>
            <h3 style={{color: "rgb(2, 53, 83)"}}>YouTube</h3>
            <p>Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein Tochterunternehmen
                der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA</p>
            <p>Datenschutzerklärung: <a href={"https://policies.google.com/privacy"}>
                https://policies.google.com/privacy</a></p>
            <p><a href={"https://www.xn--generator-datenschutzerklrung-pqc.de/"}>Muster-Datenschutzerklärung</a>
                &nbsp;der&nbsp;
                <a href={"https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html"}>
                    Anwaltskanzlei Weiß & Partner</a></p>
            <h3 style={{color: "rgb(2, 53, 83)"}}>Merkblatt zu Informationspflichten nach der DS-GVO</h3>
            <p>Die Sicherheit und der Schutz von persönlichen Daten ist uns wichtig. Weitere Informationen zum Umgang
                mit personenbezogenen Daten des Universitätssegelclubs Oldenburg e.V. finden sie in Unserem Merkblatt
                <a href={"static/dsgvo/Datenschutz-Merkblatt_USCO_F.pdf"}>
                    „Informationspflichten nach Artikel 12, 13 und 14 DS-GVO“</a>.</p>


        </div>
    );
}
