import {createTheme} from "@mui/material/styles";

const UscoTheme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorDefault: {
                    color: "#ffffff"
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: "#4c627f"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    margin: "0 0 10px"
                },
                body2: {
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px"
                },
                h1: {
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "34px",
                    fontWeight: 500,
                    lineHeight: "40px"
                },
                h2: {
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "28px",
                    textTransform: "uppercase",
                    margin: "0 0 20px"
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&$disabled": {
                        color: "#000000"
                    }
                },
                underline: {
                    "&$disabled:before": {
                        borderBottom: "1px lightgrey",
                        borderBottomStyle: "solid"
                    },
                    "&:before": {
                        borderBottom: "1px solid lightgrey"
                    },
                    "&:before:": {
                        borderBottom: "1px solid lightgrey"
                    },
                    "&:hover:not($disabled):before": {
                        borderBottom: "1px solid darkgrey"
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&:hover:not($focused)": {
                        "& $notchedOutline": {
                            borderColor: "darkgrey"
                        }
                    },
                    "&$focused": {
                        borderWidth: "1px",
                        "& $notchedOutline": {
                            borderWidth: "1px",
                            borderColor: "#4c627f"
                        },
                    },
                },
                notchedOutline: {
                    borderColor: "lightgrey"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    color: "#fff",
                    "&:hover": {
                        backgroundColor: "#3d6599"
                    },
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    "&:focus": {
                        backgroundColor: "unset"
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "#ffffff",
                    textAlign: "left",
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#4c627f",
        },
        text: {
            primary: "#000000",
            secondary: "#808080",
            disabled: "#000000"
        }
    }
});

export default UscoTheme;
