import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {useNavigate} from "react-router";
import * as ROUTES from "../../constants/routes";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    headerImage: {
        width: "205px",
        height: "70px",
        margin: "auto 0 auto 80px",
        backgroundColor: "transparent",
        [theme.breakpoints.down(1024)]: {
            marginLeft: "10px",
            transition: "margin 0.2s ease"
        },
        [theme.breakpoints.up(1024)]: {
            transition: "margin 0.2s ease"
        },
        [theme.breakpoints.down(950)]: {
            width: "150px",
            height: "50px",
            transition: "height 0.2s, width 0.2s ease"
        },
        [theme.breakpoints.up(950)]: {
            transition: "height 0.2s, width 0.2s ease"
        },
    }
}));

export default function HeaderImage() {

    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <img className={classes.headerImage}
             onClick={() => {
                 navigate(ROUTES.HOME);
             }}
             src="../static/images/logo/usco.svg"
             alt="USCO e.V. - Logo" />
    );
}
