import React, {CSSProperties, PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    row: {
        display: "flex",
        flexDirection: "row"
    },
    lastRow: {
        marginBottom: "0"
    }
}));

interface TextRowProps extends PropsWithChildren<any> {
    last?: boolean,
    rowStyle?: CSSProperties,
    rowClassName?: string
}

export default function TextRow(props: TextRowProps) {
    const {last, rowStyle, children} = props;

    const classes = useStyles();

    return (
        <div style={rowStyle} className={`${classes.row}${last ? " " + classes.lastRow : ""}`}>
            {children}
        </div>
    );
}
