import React, {CSSProperties, PropsWithChildren, SyntheticEvent, useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import Text from "./Text";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    backgroundAutocomplete: {
        "& .MuiFormControl-root": {
            backgroundColor: "#ffffff",
            borderRadius: "4px"
        }
    }
}));

export interface OptionType {
    title: string;
    value: any;
}

interface BackgroundAutocompleteProps extends PropsWithChildren<any> {
    id: string;
    label: string;
    selectedContactIdentifier: string;
    required?: boolean;
    style?: CSSProperties;
    className?: string;
    editable?: boolean;
    optionTypes: OptionType[];

    onChange(newSelectedContactIdentifier: string): void;
}

export default function BackgroundAutocomplete(props: BackgroundAutocompleteProps) {
    const {
        id, label, selectedContactIdentifier, required, style, className, optionTypes, onChange
    } = props;
    const classes = useStyles();

    const editable = props.editable !== undefined ? props.editable : true;

    const [inputValue, setInputValue] = useState('')

    const [currentValue, setCurrentValue] = useState<OptionType | null>(null);

    useEffect(() => {
        let didCancel = false;
        const currentValueIndex: number =
            optionTypes.findIndex(optTyp => optTyp.value === selectedContactIdentifier);
        if (!didCancel && currentValueIndex > -1) {
            const currentValue = optionTypes[currentValueIndex];
            setCurrentValue(currentValue);
        }
        return () => {
            didCancel = true;
        }
    }, [optionTypes, selectedContactIdentifier]);

    return (
        <>
            {editable && (
                <Autocomplete id={id}
                              style={{
                                  marginBottom: "17px",
                                  ...style
                              }}
                              className={`${className ? className + " " : ""}${classes.backgroundAutocomplete}`}
                              options={currentValue && !optionTypes.includes(currentValue) ? [currentValue, ...optionTypes] : optionTypes}
                              getOptionLabel={(option: OptionType) => option.title}
                              noOptionsText="Keine passende Auswahl"
                              isOptionEqualToValue={
                                  (option: OptionType, value: OptionType) => {
                                      if (!option || !value) {
                                          return false;
                                      } else {
                                          return option.value === value.value;
                                      }
                                  }
                              }
                              value={currentValue}
                              onChange={(event: SyntheticEvent, newValue: OptionType | null) => {
                                  setCurrentValue(newValue);
                                  onChange(newValue ? newValue.value : '');
                              }}
                              inputValue={inputValue}
                              onInputChange={(event: SyntheticEvent, newInputValue: string) => {
                                  setInputValue(newInputValue);
                              }}
                              renderInput={(params => (
                                  <TextField {...params}
                                             required={required}
                                             label={label}
                                             variant="outlined"/>
                              ))}

                />)}
            {!editable && (
                <Text label={label} value={currentValue?.title} className={className} style={style}/>)}
        </>
    );

}
