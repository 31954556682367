import React, {PropsWithChildren, useContext} from "react";
import TextGroup from "../../components/TextGroup";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import IMember, {
    DirectDebitAuthority,
    SailEntitlement
} from "../../model/member/IMember";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {UserRole} from "../../model/IUser";
import ContentCheckbox from "../../components/ContentCheckbox";

const useStyles = makeStyles(() => createStyles({
    oneColumn: {
        width: "100%"
    }
}));

interface EntitlementAreaProps extends PropsWithChildren<any> {
    member: IMember;
    directDebitAuthority: DirectDebitAuthority;
    sailEntitlement: SailEntitlement;

    onChangeDirectDebitAuthority(directDebitAuthority: DirectDebitAuthority): void;

    onChangeSailEntitlement(sailEntitlement: SailEntitlement): void;
}

export default function EntitlementArea(props: EntitlementAreaProps) {
    const {member, directDebitAuthority, sailEntitlement,
        onChangeDirectDebitAuthority, onChangeSailEntitlement} = props;
    const classes = useStyles();
    const {user} = useContext(AuthServiceContext);

    const handleAllowedToEdit = () => {
        return user.isInUserRole(UserRole.ADM_USER);
    }

    const handleDirectDebitAllowedToEdit = () => {
        return DirectDebitAuthority.NOT_GRANTED === member.bankAccount.directDebitAuthority;
    }

    const handleDirectDebitAuthorized = (): boolean => {
        return DirectDebitAuthority.GRANTED === directDebitAuthority;
    }

    const handleSailEntitlementAuthorized = (): boolean => {
        return SailEntitlement.AUTHORIZED === sailEntitlement
    }

    const handleCourseParticipantTemporaryAuthorized = (): boolean => {
        return SailEntitlement.TEMPORARY_AUTHORIZED === sailEntitlement;
    }

    return (
        <TextGroup>
            <TextRow>
                <ContentCheckbox className={classes.oneColumn}
                                 checked={handleDirectDebitAuthorized()}
                                 label="Bankverbindung geprüft / Einzug erfolgreich"
                                 editable={handleDirectDebitAllowedToEdit() && handleAllowedToEdit()}
                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                     onChangeDirectDebitAuthority(event.target.checked ?
                                         DirectDebitAuthority.GRANTED : DirectDebitAuthority.NOT_GRANTED);
                                 }}/>
            </TextRow>
            <TextRow>
                <ContentCheckbox className={classes.oneColumn}
                                 checked={handleSailEntitlementAuthorized()}
                                 label="Segelnachweis geprüft / vorgelegt"
                                 editable={!handleCourseParticipantTemporaryAuthorized() && handleAllowedToEdit()}
                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                     onChangeSailEntitlement(event.target.checked ?
                                         SailEntitlement.AUTHORIZED : SailEntitlement.UNAUTHORIZED);
                                 }}/>
            </TextRow>
            <TextRow last>
                <ContentCheckbox className={classes.oneColumn}
                                 checked={handleCourseParticipantTemporaryAuthorized()}
                                 label="Ist Kursmitglied und darf vorübergehend Boote buchen"
                                 editable={!handleSailEntitlementAuthorized() && handleAllowedToEdit()}
                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                     onChangeSailEntitlement(event.target.checked ?
                                         SailEntitlement.TEMPORARY_AUTHORIZED : SailEntitlement.UNAUTHORIZED);
                                 }}/>
            </TextRow>
        </TextGroup>
    );
}
