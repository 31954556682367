import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import TextGroup from "../../components/TextGroup";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import DialogButton from "../../components/DialogButton";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {UserRole} from "../../model/IUser";
import ContentButton from "../../components/ContentButton";
import IAssociationWorking from "../../model/association_working/IAssociationWorking";
import BackgroundTextField from "../../components/BackgroundTextField";
import BackgroundTextArea from "../../components/BackgroundTextArea";
import BackgroundAutocomplete, {OptionType} from "../../components/BackgroundAutocomplete";
import {IContact} from "../../model/member/IContact";
import {ContactServiceApi} from "../../api/MemberServiceApi";
import Text from "../../components/Text";
import IParticipant from "../../model/association_working/IParticipant";
import {DateValidationError} from "@mui/lab/internal/pickers/date-utils";
import {ParseableDate} from "@mui/lab/internal/pickers/constants/prop-types";
import {StyledTextField} from "../../components/StyledComponents";
import {DatePicker} from "@mui/lab";
import {handleDateToIsoString, handleIsoStringToDate} from "../../model/DateHandler";
import AssociationWorkingParticipant from "./AssociationWorkingParticipant";
import AssociationWorkingAddParticipant from "./AssociationWorkingAddParticipant";

const useStyles = makeStyles(() => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "#E8E7E8"
    },
    oneColumn: {
        width: "100%"
    },
    twoColumn: {
        width: "calc(66%)"
    },
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    middleColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "auto",
        marginRight: "auto"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
        marginRight: "0"
    },
    button: {
        marginRight: "10px"
    },
    lastButton: {
        marginRight: "0"
    },
    participantDeleteButton: {
        marginLeft: "5px",
        marginTop: "8px",
        height: "40px"
    },
    participantAddButton: {
        fontSize: "16px",
        marginLeft: "5px",
        marginTop: "auto",
        marginBottom: "auto"
    },
}));

interface AssociationWorkingDetailProps extends PropsWithChildren<any> {
    associationWorking: IAssociationWorking;

    onUpdate(associationWorking: IAssociationWorking): void;

    onDelete(associationWorking: IAssociationWorking): void;
}

export default function AssociationWorkingDetail(props: AssociationWorkingDetailProps) {
    const {associationWorking, onUpdate, onDelete} = props;
    const {user, getToken} = useContext(AuthServiceContext);
    const classes = useStyles();

    // model
    const [expireDate, setExpireDate] = useState<Date | null>(handleIsoStringToDate(associationWorking.expireDate));
    const [title, setTitle] = useState<string>(associationWorking.title);
    const [description, setDescription] = useState<string | undefined>(associationWorking.description);
    const [personInCharge, setPersonInCharge] = useState<string>(associationWorking.personInCharge);
    const [meetingDate, setMeetingDate] = useState<string>(associationWorking.meetingDate);
    const [meetingTime, setMeetingTime] = useState<string>(associationWorking.meetingTime);
    const [meetingPlaceName, setMeetingPlaceName] = useState<string>(associationWorking.meetingPlace.name);
    const [meetingPlaceLatitude, setMeetingPlaceLatitude] = useState<string | undefined>(associationWorking.meetingPlace.latitude);
    const [meetingPlaceLongitude, setMeetingPlaceLongitude] = useState<string | undefined>(associationWorking.meetingPlace.longitude);
    const [neededPersons, setNeededPersons] = useState<number>(associationWorking.neededPersons);
    const [neededTrailerCouplings, setNeededTrailerCouplings] = useState<number>(associationWorking.neededTrailerCouplings);
    const [participants, setParticipants] = useState<IParticipant[]>(associationWorking.participants);

    // contact model
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [optionTypes, setOptionTypes] = useState<OptionType[]>([]);

    useEffect(() => {
        ContactServiceApi.getContacts(getToken())
            .then((contacts: IContact[]) => {
                console.info("all contacts:", contacts);
                setContacts(contacts);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
                return false;
            });
    }, [getToken]);

    useEffect(() => {
        const optionTypes = contacts.map(contact => {
            return {title: contact.firstName + " " + contact.lastName + " (" + contact.mailAddress + ")", value: contact.identifier}
        });
        setOptionTypes(optionTypes);
    }, [contacts]);

    const handleAllowedToEdit = () => {
        return user.isInUserRole(UserRole.ADM_ASSOCIATION_WORKING);
    }

    const handleSave = () => {
        associationWorking.expireDate = handleDateToIsoString(expireDate);
        associationWorking.title = title;
        associationWorking.description = description ? description : "";
        associationWorking.personInCharge = personInCharge;
        associationWorking.meetingDate = meetingDate;
        associationWorking.meetingTime = meetingTime;
        associationWorking.meetingPlace.name = meetingPlaceName;
        associationWorking.meetingPlace.latitude = meetingPlaceLatitude;
        associationWorking.meetingPlace.longitude = meetingPlaceLongitude;
        associationWorking.neededPersons = neededPersons;
        associationWorking.neededTrailerCouplings = neededTrailerCouplings;
        associationWorking.participants = participants;
        onUpdate(associationWorking);
    }

    const handleDelete = () => {
        onDelete(associationWorking);
    }

    const handleModified = (): boolean => {
        return (handleDateToIsoString(expireDate) !== associationWorking.expireDate)
            || (title !== associationWorking.title)
            || (description !== associationWorking.description)
            || (personInCharge !== associationWorking.personInCharge)
            || (meetingDate !== associationWorking.meetingDate)
            || (meetingTime !== associationWorking.meetingTime)
            || (meetingPlaceName !== associationWorking.meetingPlace.name)
            || (meetingPlaceLatitude !== associationWorking.meetingPlace.latitude)
            || (meetingPlaceLongitude !== associationWorking.meetingPlace.longitude)
            || (neededPersons !== associationWorking.neededPersons)
            || (neededTrailerCouplings !== associationWorking.neededTrailerCouplings)
            || (participants !== associationWorking.participants);
    }

    const addParticipant = (participantIdentifier: string, trailerCouplingSupport: boolean) => {
        if (participantIdentifier !== "") {
            participants.push({identifier: participantIdentifier, trailerCouplingSupport: trailerCouplingSupport});
            setParticipants([...participants]);
        }
    }

    const deleteParticipant = (participantIdentifier: string) => {
        console.info("delete", participantIdentifier);
        const participantIndex = participants
            .findIndex(value => value.identifier === participantIdentifier);
        participants.splice(participantIndex, 1);
        setParticipants([...participants]);
    }

    return (
        <div key={associationWorking.number} className={classes.container}>
            <TextGroup label="Die Vereinsarbeit">
                <TextRow>
                    <Text className={classes.leftColumn}
                          label="Vereinsarbeitsnummer" value={associationWorking.number}/>
                    <DatePicker
                        label="Anzeigen bis"
                        openTo="day"
                        views={["year", "day"]}
                        value={expireDate}
                        onChange={setExpireDate}
                        onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                            console.info(reason, value);
                        }}
                        renderInput={(params) =>
                            <StyledTextField {...params}
                                             className={classes.rightColumn}
                                             required
                                             label="Anzeigen bis"
                                             placeholder="Anzeigen bis"
                            />}
                        inputFormat="dd.MM.yyyy"
                        mask="__.__.____"
                    />
                </TextRow>
            </TextGroup>
            <TextGroup label="Was">
                <TextRow>
                    <BackgroundTextField className={classes.oneColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Title"
                                         defaultValue={title}
                                         onChange={(event: any) => {
                                             setTitle(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow>
                    <BackgroundTextArea className={classes.oneColumn}
                                        editable={handleAllowedToEdit()}
                                        label="Beschreibung"
                                        rows={5}
                                        variant="outlined"
                                        value={description}
                                        onChange={(event: any) => {
                                            setDescription(event.target.value);
                                        }}/>
                </TextRow>
                <TextRow last>
                    <BackgroundAutocomplete id="personInChargeInput"
                                            className={classes.twoColumn}
                                            label="Verantwortliche"
                                            selectedContactIdentifier={personInCharge}
                                            optionTypes={optionTypes}
                                            onChange={(newValue: string) => {
                                                setPersonInCharge(newValue);
                                            }}/>
                </TextRow>
            </TextGroup>
            <TextGroup label="Wann und Wo">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Datum"
                                         defaultValue={meetingDate}
                                         onChange={(event: any) => {
                                             setMeetingDate(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Uhrzeit"
                                         defaultValue={meetingTime}
                                         onChange={(event: any) => {
                                             setMeetingTime(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow last>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Treffpunkt"
                                         defaultValue={meetingPlaceName}
                                         onChange={(event: any) => {
                                             setMeetingPlaceName(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.middleColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Längengrad (lat)"
                                         defaultValue={meetingPlaceLatitude}
                                         onChange={(event: any) => {
                                             setMeetingPlaceLatitude(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Breitengrad (long)"
                                         defaultValue={meetingPlaceLongitude}
                                         onChange={(event: any) => {
                                             setMeetingPlaceLongitude(event.target.value);
                                         }}/>
                </TextRow>
            </TextGroup>
            <TextGroup label="Wer kann sich anmelden">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Anzahl Plätze"
                                         defaultValue={neededPersons}
                                         onChange={(event: any) => {
                                             setNeededPersons(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Anzahl benötigter AHK"
                                         defaultValue={neededTrailerCouplings}
                                         onChange={(event: any) => {
                                             setNeededTrailerCouplings(event.target.value);
                                         }}/>
                </TextRow>
            </TextGroup>
            <TextGroup label="Wer hat sich schon angemeldet">
                {participants.map((participant: IParticipant, index: number) => (
                    <AssociationWorkingParticipant key={"participant_" + index}
                                                   participant={participant}
                                                   index={index}
                                                   participantLength={participants.length}
                                                   contacts={contacts}
                                                   onDeleteParticipant={deleteParticipant}/>
                ))}
                <AssociationWorkingAddParticipant associationWorking={associationWorking}
                                                  participants={participants}
                                                  optionTypes={optionTypes}
                                                  onAddParticipant={addParticipant} />
            </TextGroup>
            {handleAllowedToEdit() && (<div className={classes.buttonContainer}>
                <DialogButton label="Löschen"
                              title="Vereinsarbeit wirklich löschen?"
                              variant="secondary"
                              actionLabel="Löschen"
                              onActionClick={handleDelete}
                              buttonClassName={classes.button}/>
                <ContentButton className={classes.lastButton}
                               variant="primary"
                               disabled={!handleModified()}
                               onClick={handleSave}>Speichern</ContentButton>

            </div>)}
        </div>
    );
}
