import React, {CSSProperties, PropsWithChildren} from "react";
import {StyledSelect} from "./StyledComponents";
import InputLabel from "@mui/material/InputLabel";
import {FormHelperText, SelectChangeEvent} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Text from "./Text";

interface BackgroundDropdownProps extends PropsWithChildren<any> {
    id: string;
    label: string;
    value?: any;
    defaultValue?: any;
    nonValue: any;
    errorMessage?: string;
    validated?: boolean;
    required?: boolean;
    style?: CSSProperties;
    className?: string;
    useValue?: boolean;
    editable?: boolean;

    onChange(event: SelectChangeEvent<any>, client: React.ReactNode): void;
}

export default function BackgroundDropdown(props: BackgroundDropdownProps) {
    const {
        id, label, value, defaultValue, nonValue, errorMessage, validated,
        required, style, className, useValue, onChange, children
    } = props;

    const editable = props.editable !== undefined ? props.editable : true;

    return (
        <>
            {editable && (
                <FormControl variant="outlined" style={{ marginBottom: "17px", ...style }} className={className}>
                    <InputLabel id={id}
                                required={required}
                                error={validated && value === nonValue}>{label}</InputLabel>
                    <StyledSelect labelId={id}
                                  label={label}
                                  error={validated && (value === nonValue)}
                                  value={!!useValue ? value : null}
                                  defaultValue={!useValue ? (!!defaultValue ? defaultValue : nonValue) : null}
                                  onChange={onChange}>
                        {children}
                    </StyledSelect>
                    <FormHelperText error={validated && value === nonValue}>
                        {validated && value === nonValue ? errorMessage : null}
                    </FormHelperText>
                </FormControl>)}
            {!editable && (
                <Text label={label} value={value} className={className} style={style}/>)}
        </>
    );

}
