import React, {useState} from "react";
import DataTable, {DataHeaderColumn, DataTableRow} from "../../components/DataTable";
import {createStyles, makeStyles} from "@mui/styles";
import ContentContainer from "../../components/ContentContainer";
import {groupToText, UserGroup, UserRole} from "../../model/IUser";
import GroupDetail from "./GroupDetail";
import {IGroup} from "../../model/member/IGroup";

const useStyles = makeStyles(() => createStyles({
    headline: {
        textAlign: "center",
        marginBottom: "40px"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    tabContent: {
        display: "flex",
        flexDirection: "column",
    }
}));

const groups: IGroup[] = [
    {
        type: UserGroup.BOARD_MEMBER,
        rights: [
            UserRole.EDIT_CONTENT,
            UserRole.EDIT_COURSE,
            UserRole.ADM_USER,
            UserRole.ADM_ASSOCIATION_WORKING,
            UserRole.ADM_NEWSLETTER,
            UserRole.ADM_BOAT,
        ],
        officeAccess: true,
        description: "Die Nutzergruppe Vorstand ermöglicht dem Nutzer alles zu bearbeiten."
    },
    {
        type: UserGroup.BOATSWAIN,
        rights: [
            UserRole.EDIT_BOAT
        ],
        officeAccess: false,
        description: "Die Nutzergruppe Bootsmann/Bootsfrau ermöglicht dem Nutzer das eigenen Boot zu sperren oder deren Inhalte zu editieren."
    },
    {
        type: UserGroup.ORGANIZER,
        rights: [
            UserRole.ADM_ASSOCIATION_WORKING,
            UserRole.ADM_NEWSLETTER
        ],
        officeAccess: true,
        description: "Die Nutzergruppe Organisator/Organisatorin ermöglicht dem Nutzer Vereinsarbeiten einzustellen und zu bearbeiten, sowie Nachrichten dazu zu verfassen."
    },
    {
        type: UserGroup.INSTRUCTOR,
        rights: [
            UserRole.EDIT_COURSE,
            UserRole.MULTI_BOOKING
        ],
        officeAccess: false,
        description: "Die Nutzergruppe Trainer/Trainerin ermöglicht dem Nutzer mehrere Bootsbuchen durchzuführen und den eigenen Kurz zu bearbeiten."
    },
    {
        type: UserGroup.ADMINISTRATOR,
        rights: [
            UserRole.ADM_USER
        ],
        officeAccess: true,
        description: "Die Nutzergruppe Administrator ermöglicht dem Nutzer die Mitgliederbearbeitung."
    }
];

export default function Groups() {
    const [loading] = useState<boolean>(false);
    const [selectedGroup, setSelectedGroup] = useState<string | undefined>(undefined);

    const classes = useStyles();

    const handleGroupSelection = (selectedId: string) => {
        setSelectedGroup(selectedId);
    }

    const groupHeaderColumns: DataHeaderColumn<IGroup>[] = [
        {value: "type", sortable: true, node: "Nutzergruppen"}
    ];

    const groupDataRows: DataTableRow<IGroup>[] = groups.map((group: IGroup, index: number) => ({
        value: group,
        nodes: [
            <>{groupToText(group.type)}</>
        ],
        detail:
            <GroupDetail group={group} index={index}/>
    }));

    const uniqueKeyColumn = "type";

    return (
        <ContentContainer process={loading}>
            <h1 className={classes.headline}>Nutzergruppen-Verwaltung</h1>
            <div className={classes.content}>
                <div className={classes.tabContent}>
                    <DataTable headerColumns={groupHeaderColumns}
                               dataRows={groupDataRows}
                               uniqueKeyColumn={uniqueKeyColumn}
                               selected={selectedGroup}
                               onSelection={handleGroupSelection}/>
                </div>
            </div>
        </ContentContainer>
    );
}
