import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import {IContact} from "../../model/member/IContact";
import Text from "../../components/Text";
import IParticipant from "../../model/association_working/IParticipant";
import {Icon, IconButton} from "@mui/material";

const useStyles = makeStyles(() => createStyles({
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    participantDeleteButton: {
        marginLeft: "5px",
        marginTop: "8px",
        height: "40px"
    },
}));

interface AssociationWorkingParticipantProps extends PropsWithChildren<any> {
    participant: IParticipant;
    index: number;
    participantLength: number;
    contacts: IContact[];

    onDeleteParticipant(participantIdentifier: string): void;
}

export default function AssociationWorkingParticipant(props: AssociationWorkingParticipantProps) {
    const {participant, index, participantLength, contacts, onDeleteParticipant} = props;
    const classes = useStyles();

    // model

    const convertToParticipantContact = (participant: IParticipant) => {
        let participantContact: IContact | undefined = undefined;

        const contactIndex = contacts.findIndex(contact => contact.identifier === participant.identifier);
        if (contactIndex > -1) {
            participantContact = contacts[contactIndex];
        }

        return participantContact ? participantContact.firstName + " " + participantContact.lastName + " (" + participantContact.mailAddress + ")" : "";
    }

    return (
        <TextRow key={participant.identifier}
                 last={(index + 1) === participantLength}>
            <Text className={classes.leftColumn}
                  label="Teilnehmer"
                  value={
                      convertToParticipantContact(participant)
                  }/>
            <Text className={classes.rightColumn}
                  label="AHK"
                  value={participant.trailerCouplingSupport ? "Ja" : "Nein"}/>
            <IconButton className={classes.participantDeleteButton}
                        onClick={() => {
                            onDeleteParticipant(participant.identifier);
                        }}><Icon>delete</Icon></IconButton>
        </TextRow>
    );
}
