import React, {useContext, useEffect, useState} from "react";
import DataTable, {DataHeaderColumn, DataTableRow} from "../../components/DataTable";
import MemberDetail from "./MemberDetail";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {createStyles, makeStyles} from "@mui/styles";
import IMember, {membershipToText} from "../../model/member/IMember";
import {MemberServiceApi} from "../../api/MemberServiceApi";
import ContentContainer from "../../components/ContentContainer";

const useStyles = makeStyles(() => createStyles({
    headline: {
        textAlign: "center",
        marginBottom: "40px"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    tabContent: {
        display: "flex",
        flexDirection: "column",
    }
}));


export default function Members() {
    const [loading, setLoading] = useState<boolean>(false);
    const [members, setMembers] = useState<IMember[]>([]);
    const [selectedMember, setSelectedMember] = useState<string | undefined>(undefined);

    const {getToken} = useContext(AuthServiceContext);
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        MemberServiceApi.getMembers(getToken())
            .then(response => {
                const members = response.sort((a: IMember, b: IMember) => {
                    return Number(a.identifier).valueOf() - Number(b.identifier).valueOf();
                });
                setMembers(members);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getToken]);

    const handleUpdate = (member: IMember) => {
        setLoading(true);
        MemberServiceApi.updateMember(member, getToken())
            .then((updatedMember: IMember) => {
                handleChange(updatedMember);
                setSelectedMember(undefined);
                console.info("updated member:", updatedMember);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleDelete = (member: IMember) => {
        setLoading(true);
        MemberServiceApi.deleteMember(member, getToken())
            .then(() => {
                const filteredMembers = members.filter(function (mem: IMember) {
                    return mem.identifier !== member.identifier;
                });
                setMembers(filteredMembers);
                setSelectedMember(undefined);
                console.info("deleted member:", member);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleChange = (updatedMember: IMember) => {
        const memberIndex = members.findIndex(data => data.identifier === updatedMember.identifier);
        members.splice(memberIndex, 1, JSON.parse(JSON.stringify(updatedMember)));
        setMembers([...members]);
    }

    const handleMemberSelection = (selectedId: string) => {
        setSelectedMember(selectedId);
    }

    const memberHeaderColumns: DataHeaderColumn<IMember>[] = [
        {value: "lastName", sortable: true, node: "Nachname"},
        {value: "firstName", sortable: true, node: "Vorname"},
        {value: "username", sortable: true, node: "Nutzername"},
        {value: "mailAddress", sortable: false, node: "E-Mail-Adresse"},
        {value: "membership", sortable: true, node: "Art der Mitgliedschaft"},
        {value: "locked", sortable: false, node: "Status"}
    ];

    const memberDataRows: DataTableRow<IMember>[] = members.map((member) => ({
        value: member,
        nodes: [
            <>{member.lastName}</>,
            <>{member.firstName}</>,
            <>{member.username}</>,
            <>{member.mailAddress}</>,
            <>{membershipToText(member.membership)}</>,
            <>{member.locked ? "gesperrt" : "normal"}</>
        ],
        detail:
            <MemberDetail member={member}
                          onUpdate={handleUpdate}
                          onDelete={handleDelete}
                          onChange={handleChange}/>
    }));

    const uniqueKeyColumn = "username";

    return (
        <ContentContainer process={loading}>
            <h1 className={classes.headline}>Mitgliederverwaltung</h1>
            <div className={classes.content}>
                <div className={classes.tabContent}>
                    <DataTable headerColumns={memberHeaderColumns}
                               dataRows={memberDataRows}
                               uniqueKeyColumn={uniqueKeyColumn}
                               selected={selectedMember}
                               onSelection={handleMemberSelection}/>
                </div>
            </div>
        </ContentContainer>
    );
}
