import {withStyles} from "@mui/styles";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import {styled} from "@mui/material/styles";

export const StyledTextField = withStyles({
    root: {
        marginBottom: "20px",
        "& .MuiOutlinedInput-root": {
            "& input": {
                backgroundColor: "#ffffff",
                borderRadius: "4px",
                "&.Mui-disabled": {
                    backgroundColor: "transparent"
                }
            }
        },
    },
})(TextField);

export const StyledSelect = styled(Select)(() => ({
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    "&:focus": {
        backgroundColor: "#ffffff !important"
    }
}));
