import React, {useContext, useState} from "react";
import {Button, Icon} from "@mui/material";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import {DropdownMenu, DropdownMenuItem} from "../../../components/menu/DropdownMenu";
import {useNavigate} from "react-router";
import {makeStyles} from "@mui/styles";
import * as ROUTES from "../../../constants/routes";
import {DrawerMenu, DrawerMenuItem} from "../../../components/menu/DrawerMenu";

const useStyles = makeStyles((theme) => ({
    spacingRight: {
        marginRight: "10px"
    },
    loginMenuLink: {
        width: "172px",
        height: "100%",
        lineHeight: "26px",
        color: "#ffffff",
        fontFamily: "'Roboto Mono', monospace",
        fontSize: "20px",
        textDecoration: "none",
        backgroundColor: "#02B8B7",
        borderRadius: "0",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.8)"
        }
    }
}));

export function LoginMenu() {
    const classes = useStyles();
    const navigate = useNavigate();
    const {user, login, logout} = useContext(AuthServiceContext);

    const [open, setOpen] = useState(false);

    const handleLogin = () => {
        login();
    }

    const handleLogout = () => {
        navigate(ROUTES.HOME);
        logout();
    }

    return (
        <>
            {(() => {
                if (user.authenticated) {
                    return (
                        <>
                            <DropdownMenu title={<Icon>account_circle</Icon>}
                                          className={"menuLink"}
                                          state={open}
                                          onStateChange={setOpen}>
                                <DropdownMenuItem target={ROUTES.PROFILE}
                                                  title="Profil"
                                                  className="subMenuLink"
                                                  onStateChange={setOpen}/>
                                <DropdownMenuItem target={handleLogout}
                                                  title="Logout"
                                                  className="subMenuLink"
                                                  onStateChange={setOpen}/>
                            </DropdownMenu>
                        </>
                    )
                } else {
                    return (
                        <Button variant={"text"}
                                className={classes.loginMenuLink}
                                onClick={handleLogin}><Icon style={{ fontSize: "20px", marginRight: "10px" }}>login</Icon>Log In</Button>
                    )
                }
            })()}
        </>
    );
}

export function LoginDrawerMenu() {
    const navigate = useNavigate();
    const {user, login, logout} = useContext(AuthServiceContext);

    const handleLogin = () => {
        login();
    }

    const handleLogout = () => {
        navigate(ROUTES.HOME);
        logout();
    }

    return (
        <>
            {user.authenticated && (
                <DrawerMenu title={`${user.firstName} ${user.lastName}`}>
                    <DrawerMenuItem target={ROUTES.PROFILE} title="Profile" className="subMenuLink" />
                    <DrawerMenuItem target={handleLogout} title="Logout" className="subMenuLink" />
                </DrawerMenu>
            )}
            {!user.authenticated && (
                <DrawerMenu divider={false}>
                    <DrawerMenuItem itemStyle={{ backgroundColor: "#02A1A0", color: "#ffffff" }}
                                    target={handleLogin}
                                    className="subMenuLink"
                                    title="Log In" />
                </DrawerMenu>
            )}
        </>
    );
}
