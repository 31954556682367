import {UserGroup} from "../IUser";

export enum Membership {
    NONE = "NONE",
    STUDENT = "STUDENT",
    SINGLE = "SINGLE",
    JUNIOR = "JUNIOR",
    FAMILY = "FAMILY",
}

export function membershipToText(membership: Membership) {
    switch (membership) {
        case Membership.SINGLE:
            return "Einzelperson";
        case Membership.STUDENT:
            return "Student";
        case Membership.JUNIOR:
            return "Kind";
        case Membership.FAMILY:
            return "Familie";
        case Membership.NONE:
            return "Keine Auswahl";
        default:
            return "";
    }
}

export enum Gender {
    NONE = "NONE",
    MALE = "MALE",
    FEMALE = "FEMALE",
    DEVICE = "DEVICE"
}

export function genderToText(gender: Gender) {
    switch (gender) {
        case Gender.MALE:
            return "männlich";
        case Gender.FEMALE:
            return "weiblich";
        case Gender.DEVICE:
            return "divers";
        case Gender.NONE:
            return "Keine Auswahl";
        default:
            return "";
    }
}

export enum DirectDebitAuthority {
    GRANTED = "GRANTED",
    NOT_GRANTED = "NOT_GRANTED"
}

export enum SailEntitlement {
    AUTHORIZED = "AUTHORIZED",
    TEMPORARY_AUTHORIZED = "TEMPORARY_AUTHORIZED",
    UNAUTHORIZED = "UNAUTHORIZED"
}

export interface IMemberContact {
    address: IMemberAddress;
    phoneNumber: string | undefined;
    mobileNumber: string | undefined;
}

export interface IMemberAddress {
    streetLine: string;
    zipCode: string;
    city: string;
}

export interface IMemberBankAccount {
    accountHolder: string;
    iban: string;
    bic: string;
    bankName: string;
    directDebitAuthority: DirectDebitAuthority;
}

export interface IMemberGroup {
    group: UserGroup;
}

export interface IMemberIdentifier {
    identifier: string;
}

export default interface IMember {
    identifier: string;
    username: string;
    membership: Membership;
    mailAddress: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    sailEntitlement: SailEntitlement;
    contact: IMemberContact;
    bankAccount: IMemberBankAccount;
    locked: boolean;
    principleIdentifier: string;
}
