import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import IBoat, {IBoatswain, IOutOfOrder, ITechnicalData, Location, locationText} from "../../model/IBoat";
import TextGroup from "../../components/TextGroup";
import {createStyles, makeStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import TextRow from "../../components/TextRow";
import DialogButton from "../../components/DialogButton";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {UserGroup, UserRole} from "../../model/IUser";
import BackgroundDropdown from "../../components/BackgroundDropdown";
import BackgroundTextArea from "../../components/BackgroundTextArea";
import ContentButton from "../../components/ContentButton";
import Text from "../../components/Text";
import BackgroundTextField from "../../components/BackgroundTextField";
import {IContact} from "../../model/member/IContact";
import {ContactServiceApi, GroupServiceApi} from "../../api/MemberServiceApi";
import {IMemberIdentifier} from "../../model/member/IMember";
import BackgroundAutocomplete, {OptionType} from "../../components/BackgroundAutocomplete";
import {BoatServiceApi, BoatswainServiceApi} from "../../api/BoatServiceApi";

const useStyles = makeStyles(() => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "#E8E7E8"
    },
    oneColumn: {
        width: "100%"
    },
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    middleColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "auto",
        marginRight: "auto"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "0",
        marginRight: "0"
    },
    button: {
        marginRight: "10px"
    },
    lastButton: {
        marginLeft: "0",
        marginRight: "0"
    }
}));

const findBoatswain = (boatswains: IBoatswain[], position: number): string => {
    const boatswainIndex = boatswains.findIndex(boatswain => boatswain.position === position);
    return boatswainIndex !== -1 ? boatswains[boatswainIndex].identifier : "";
}

const findTechnicalData = (technicalData: ITechnicalData | undefined, data: keyof ITechnicalData): string => {
    return technicalData && technicalData[data] ? technicalData[data] : ""
}

const createBoatswain = (boatswainIdentifier: string, position: number): IBoatswain => {
    return {identifier: boatswainIdentifier, position: position};
}

const createOptionType = (contact: IContact): OptionType => {
    return {title: contact.firstName + " " + contact.lastName + " (" + contact.mailAddress + ")", value: contact.identifier}
}

interface BoatDetailProps extends PropsWithChildren<any> {
    boat: IBoat;

    onUpdate(boat: IBoat): void;

    onDelete(boat: IBoat): void;

    onChange(boat: IBoat): void;
}

export default function BoatDetail(props: BoatDetailProps) {
    const {boat, onUpdate, onDelete, onChange} = props;
    const {user, getToken} = useContext(AuthServiceContext);
    const classes = useStyles();

    // model
    const [name, setName] = useState<string>(boat.name);
    const [type, setType] = useState(boat.type);
    const [location, setLocation] = useState<Location>(boat.location);
    const [maxPerson, setMaxPerson] = useState<string>(!boat.maxPerson ? "" : boat.maxPerson);
    const [specifics, setSpecifics] = useState<string>(!boat.specifics ? "" : boat.specifics);
    const [broken, setBroken] = useState<boolean>(boat.outOfOrder ? boat.outOfOrder.broken : false);
    const [brokenMessage, setBrokenMessage] = useState<string>(boat.outOfOrder && boat.outOfOrder.brokenMessage ? boat.outOfOrder.brokenMessage : "");
    // technical data
    const [length, setLength] = useState<string>(findTechnicalData(boat.technicalData, "length"));
    const [width, setWidth] = useState<string>(findTechnicalData(boat.technicalData, "width"));
    const [draft, setDraft] = useState<string>(findTechnicalData(boat.technicalData, "draft"));
    const [mastAltitude, setMastAltitude] = useState<string>(findTechnicalData(boat.technicalData, "mastAltitude"));
    const [weight, setWeight] = useState<string>(findTechnicalData(boat.technicalData, "weight"));
    // boatswain model
    const [firstBoatswain, setFirstBoatswain] = useState<string>("");
    const [secondBoatswain, setSecondBoatswain] = useState<string>("");

    // contact model
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [firstBoatswainContact, setFirstBoatswainContact] = useState<IContact | undefined>(undefined);
    const [secondBoatswainContact, setSecondBoatswainContact] = useState<IContact | undefined>(undefined);
    const [optionTypes, setOptionTypes] = useState<OptionType[]>([]);
    // members of group
    const [groupMembers, setGroupMembers] = useState<IMemberIdentifier[]>([]);
    // members of boatswains
    const [boatswains, setBoatswains] = useState<IBoatswain[]>([]);

    useEffect(() => {
        let didCancel = false;
        const fetchContacts = async (): Promise<void> => {
            const contacts: IContact[] = await ContactServiceApi.getContacts(getToken());
            const groupMembers: IMemberIdentifier[] = await GroupServiceApi.getMembersOfGroup(UserGroup.BOATSWAIN, getToken());
            const boatswains: IBoatswain[] = await BoatswainServiceApi.getBoatswains(getToken());
            // initial boatswains
            const initialFirstBoatswain = findBoatswain(boat.boatswains, 1);
            const firstBoatswainIndex = contacts.findIndex(contact => contact.identifier === initialFirstBoatswain);
            const initialSecondBoatswain = findBoatswain(boat.boatswains, 2);
            const secondBoatswainIndex = contacts.findIndex(contact => contact.identifier === initialSecondBoatswain);
            // store data
            if (!didCancel) {
                setContacts(contacts);
                setGroupMembers(groupMembers);
                setBoatswains(boatswains);
                setFirstBoatswain(initialFirstBoatswain);
                if (firstBoatswainIndex !== -1) {
                    setFirstBoatswainContact(contacts[firstBoatswainIndex]);
                }
                setSecondBoatswain(initialSecondBoatswain);
                if (secondBoatswainIndex !== -1) {
                    setSecondBoatswainContact(contacts[secondBoatswainIndex]);
                }
            }
        }
        fetchContacts()
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            });
        return () => {
            didCancel = true;
        }
    }, [boat, getToken]);

    useEffect(() => {
        let didCancel = false;
        const optionTypes = contacts
            // contact is of group BOATSWAIN
            .filter(contact => groupMembers.map(groupMember => groupMember.identifier).includes(contact.identifier))
            // contact is not assigned as BOATSWAIN
            .filter(contact => !boatswains.map(boatswain => boatswain.identifier).includes(contact.identifier))
            .map(contact => {
                return createOptionType(contact);
            });
        if (!didCancel) {
            if (firstBoatswainContact) {
                optionTypes.push(createOptionType(firstBoatswainContact));
            }
            if (secondBoatswainContact) {
                optionTypes.push(createOptionType(secondBoatswainContact));
            }
            setOptionTypes(optionTypes);
        }
        return () => {
            didCancel = true;
        }
    }, [contacts, groupMembers, boatswains, firstBoatswainContact, secondBoatswainContact]);

    const handleAllowedToEdit = () => {
        return user.isInUserRole(UserRole.ADM_BOAT);
    }

    const handleSave = () => {
        const boatswains: IBoatswain[] = [];
        if (firstBoatswain) {
            boatswains.push(createBoatswain(firstBoatswain, 1));
        }
        if (secondBoatswain) {
            boatswains.push(createBoatswain(secondBoatswain, 2));
        }
        boat.name = name;
        boat.type = type;
        boat.location = location;
        boat.maxPerson = maxPerson;
        boat.specifics = specifics;
        boat.technicalData = convertTechnicalData();
        boat.outOfOrder = {broken: broken, brokenMessage: brokenMessage};
        boat.boatswains = [...boatswains];
        onUpdate(boat);
    }

    const handleDelete = () => {
        onDelete(boat);
    }

    const handleStateChange = (broken: boolean, brokenMessage: string | undefined) => {
        const outOfOrder: IOutOfOrder = { broken: broken, brokenMessage: brokenMessage}
        BoatServiceApi.updateBoatState(boat, outOfOrder, getToken())
            .then((updatedBoat: IBoat) => {
                onChange(updatedBoat);
                setBroken(updatedBoat.outOfOrder ? updatedBoat.outOfOrder.broken : false);
                setBrokenMessage(updatedBoat.outOfOrder && updatedBoat.outOfOrder.brokenMessage ? updatedBoat.outOfOrder.brokenMessage : "");
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
    }

    const handleModified = (): boolean => {
        return (name !== boat.name)
            || (type !== boat.type)
            || (location !== boat.location)
            || (maxPerson !== (boat.maxPerson ? boat.maxPerson : ""))
            || (specifics !== (boat.specifics ? boat.specifics : ""))
            || (firstBoatswain !== findBoatswain(boat.boatswains, 1))
            || (secondBoatswain !== findBoatswain(boat.boatswains, 2))
            || (length !== findTechnicalData(boat.technicalData, "length"))
            || (width !== findTechnicalData(boat.technicalData, "width"))
            || (draft !== findTechnicalData(boat.technicalData, "draft"))
            || (mastAltitude !== findTechnicalData(boat.technicalData, "mastAltitude"))
            || (weight !== findTechnicalData(boat.technicalData, "weight"))
         ;
    }

    const convertTechnicalData = (): ITechnicalData => {
        return {
            length: length,
            width: width,
            draft: draft,
            mastAltitude: mastAltitude,
            weight: weight
        }
    }

    return (
        <div key={boat.number} className={classes.container}>
            <TextGroup label="Das Boot">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Bootsname"
                                         defaultValue={name}
                                         onChange={(event: any) => {
                                             setName(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.middleColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Bootstyp"
                                         defaultValue={type}
                                         onChange={(event: any) => {
                                             setType(event.target.value);
                                         }}/>
                    <Text className={classes.rightColumn}
                          label="Bootsnummer" value={boat.number}/>
                </TextRow>
                <TextRow>
                    <BackgroundDropdown id="boat-detail-location-label"
                                        label="Liegeplatz"
                                        className={classes.leftColumn}
                                        editable={handleAllowedToEdit()}
                                        useValue={true}
                                        value={handleAllowedToEdit() ? location : locationText(boat.location)}
                                        defaultValue={location}
                                        nonValue={Location.NONE}
                                        errorMessage="Liegeplatz nicht ausgewählt."
                                        validated={false}
                                        required={true}
                                        onChange={(event: any) => {
                                            setLocation(event.target.value);
                                        }}>
                        <MenuItem value={Location.NONE} disabled>{locationText(Location.NONE)}</MenuItem>
                        <MenuItem value={Location.BORNHORST}>{locationText(Location.BORNHORST)}</MenuItem>
                        <MenuItem value={Location.BUENTING}>{locationText(Location.BUENTING)}</MenuItem>
                        <MenuItem value={Location.EYHAUSEN}>{locationText(Location.EYHAUSEN)}</MenuItem>
                        <MenuItem value={Location.OELTJEN}>{locationText(Location.OELTJEN)}</MenuItem>
                    </BackgroundDropdown>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Maximale Personenzahl"
                                         defaultValue={maxPerson}
                                         onChange={(event: any) => {
                                             setMaxPerson(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow last>
                    <Text className={classes.leftColumn}
                          label="Gesperrt"
                          value={broken ? "Ja" : "Nein"}/>
                    <Text className={classes.rightColumn}
                          label="Begründung"
                          value={broken ? brokenMessage : "keine Angabe"}/>
                </TextRow>
            </TextGroup>
            <TextGroup label="Besonderheiten">
                <TextRow last>
                    <BackgroundTextArea className={classes.oneColumn}
                                        editable={handleAllowedToEdit()}
                                        rows={5}
                                        variant="outlined"
                                        value={specifics}
                                        onChange={(event: any) => {
                                            setSpecifics(event.target.value);
                                        }}/>
                </TextRow>
            </TextGroup>
            <TextGroup label="Bootsleute">
                <TextRow>
                    <BackgroundAutocomplete id="boat-detail-first-boatswain"
                                            className={classes.leftColumn}
                                            label="1. Bootsmann"
                                            selectedContactIdentifier={firstBoatswain}
                                            optionTypes={optionTypes.filter(optionType => optionType.value !== secondBoatswain)}
                                            onChange={(newValue: string) => {
                                                setFirstBoatswain(newValue);
                                            }}/>
                </TextRow>
                <TextRow last>
                    <BackgroundAutocomplete id="boat-detail-second-boatswain"
                                            className={classes.leftColumn}
                                            label="2. Bootsmann"
                                            selectedContactIdentifier={secondBoatswain}
                                            optionTypes={optionTypes.filter(optionType => optionType.value !== firstBoatswain)}
                                            onChange={(newValue: string) => {
                                                setSecondBoatswain(newValue);
                                            }}/>
                </TextRow>
            </TextGroup>
            <TextGroup label="Technische Daten">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Länge"
                                         defaultValue={length}
                                         onChange={(event: any) => {
                                             setLength(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.middleColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Breite"
                                         defaultValue={width}
                                         onChange={(event: any) => {
                                             setWidth(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Tiefgang"
                                         defaultValue={draft}
                                         onChange={(event: any) => {
                                             setDraft(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow last>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Masthöhe"
                                         defaultValue={mastAltitude}
                                         onChange={(event: any) => {
                                             setMastAltitude(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Gewicht"
                                         defaultValue={weight}
                                         onChange={(event: any) => {
                                             setWeight(event.target.value);
                                         }}/>
                </TextRow>
            </TextGroup>

            {handleAllowedToEdit() && (<div className={classes.buttonContainer}>
                <DialogButton label={broken ? "Entsperren" : "Sperren"}
                              variant="secondary"
                              actionLabel={broken ? "Entsperren" : "Sperren"}
                              title={`Soll das Boot wirklich ${broken ? "ent" : "ge"}sperrt werden?`}
                              details={`${broken ? "Das Boot wurde aus folgendem Grund gesperrt:" 
                                  : "Du musst bitte noch einen Grund angeben."}`}
                              onActionClick={() => {
                                  handleStateChange(!broken, brokenMessage);
                              }}
                              onValidateAction={() => {
                                  return broken || (!broken && brokenMessage.length > 0);
                              }}
                              buttonStyle={{ marginLeft: "0", marginRight: "0" }}>
                    <>
                        {!broken && (
                            <BackgroundTextField variant="standard"
                                                 style={{ width: "100%" }}
                                                 required={true}
                                                 label="Grund für die Sperrung des Bootes"
                                                 value={brokenMessage}
                                                 placeholder="Dein Grund für die Sperrung des Bootes"
                                                 onChange={(event: any) => {
                                                     setBrokenMessage(event.target.value)
                                                 }}/>)}
                        {broken && (<p>
                            "{brokenMessage}"
                        </p>)}
                        <p style={{ fontSize: "14px" }}>
                            <span style={{ textDecoration: "underline"}}>Hinweis:</span>
                            <br/>Alle Mitglieder mit einer Buchung in der Zukunft werden
                            über diese Änderung informiert.
                        </p>
                    </>
                </DialogButton>
                <DialogButton label="Löschen"
                              title="Boot wirklich löschen?"
                              variant="secondary"
                              actionLabel="Löschen"
                              onActionClick={handleDelete}
                              buttonClassName={classes.button}/>
                <ContentButton className={classes.lastButton}
                               variant="primary"
                               disabled={!handleModified()}
                               onClick={handleSave}>Speichern</ContentButton>

            </div>)}
        </div>
    );
}
