import React, {useContext} from "react";
import {AuthServiceContext} from "../provider/AuthServiceProvider";
import {Typography} from "@mui/material";

export default function Profile() {
    const {user} = useContext(AuthServiceContext);

    return (
        <>
            <Typography>Vorname: {user.firstName ? user.firstName : ""}</Typography>
            <Typography>Nachname: {user.lastName ? user.lastName : ""}</Typography>
            <Typography>User: {user.username}</Typography>
            <Typography>MailAddress: {user.mailAddress}</Typography>
            <Typography>Rollen: {user.roles ? user.roles.join() : "keine"}</Typography>
        </>
    );
}