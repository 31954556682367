import React, {CSSProperties, PropsWithChildren} from "react";
import {StyledTextField} from "./StyledComponents";
import Text from "./Text";

interface EditableTextFieldProps extends PropsWithChildren<any> {
    onChange?(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    editable?: boolean;
    label: string;
    defaultValue?: string | number;
    variant?: "outlined" | "filled" | "standard";
    required?: boolean;
    style?: CSSProperties;
    className?: string
}

export default function BackgroundTextField(props: EditableTextFieldProps) {
    const {label, defaultValue, variant, required, style, className, onChange} = props;

    const editable = props.editable !== undefined ? props.editable : true;

    return (
        <>
            {editable && (<StyledTextField className={className}
                                           style={style}
                                           required={required}
                                           variant={variant}
                                           label={label}
                                           defaultValue={defaultValue}
                                           onChange={onChange}/>)}
            {!editable && (<Text label={label}
                                 value={defaultValue}
                                 className={className}/>)}
        </>
    );

}

BackgroundTextField.defaultProps = {
    variant: "outlined"
}
