import React, {CSSProperties, PropsWithChildren, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {createStyles, makeStyles} from "@mui/styles";

// root: {
//         "& .MuiFilledInput-root": {
//             backgroundColor: "transparent",
//             borderRadius: "4px",
//         },
//         "& .MuiFilledInput-root .MuiSelect-select:focus": {
//             backgroundColor: "transparent",
//             borderRadius: "4px",
//         },
//         "& .MuiFilledInput-root .MuiFilledInput-input": {
//             color: "#023553"
//         },
//         "& .MuiFilledInput-underline:before": {
//             content: "unset"
//         },
//         "& .MuiFilledInput-underline:after": {
//             content: "unset"
//         }
//     },
//

const useStyles = makeStyles(() => createStyles({
    container: {
        marginBottom: "20px"
    },
    label: {
        "& .MuiFormControlLabel-label": {
            marginBottom: 0
        }
    }
}));

interface ContentCheckboxProps extends PropsWithChildren<any> {
    onChange(event: React.ChangeEvent<HTMLInputElement>): void,

    id?: string,
    editable?: boolean,
    checked: boolean,
    label: string,
    style?: CSSProperties,
    className?: string,
}

export default function ContentCheckbox(props: ContentCheckboxProps) {
    const {onChange, id, checked, label, style, className} = props;
    const editable = props.editable !== undefined ? props.editable : true;

    const [selected, setSelected] = useState<boolean>(checked);

    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(event.target.checked);
        onChange(event);
    }

    return (
        <div id={id} style={style} className={`${!!className ? className + " " : ""}${classes.container}`}>
            <FormControlLabel
                className={classes.label}
                label={label}
                control={<Checkbox checked={selected}
                                   onChange={handleChange}
                                   disabled={!editable}
                                   color="primary"/>}/>
        </div>
    );
}