import React, {useContext, useEffect, useState} from "react";
import DataTable, {DataHeaderColumn, DataTableRow} from "../../components/DataTable";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {createStyles, makeStyles} from "@mui/styles";
import {membershipToText} from "../../model/member/IMember";
import {MemberServiceApi, RegisterServiceApi} from "../../api/MemberServiceApi";
import IRegistrationData from "../../model/member/IRegistrationData";
import RegistrationDataDetail from "./RegistrationDataDetail";
import {useNavigate} from "react-router";
import * as ROUTES from "../../constants/routes";
import ContentContainer from "../../components/ContentContainer";

const useStyles = makeStyles(() => createStyles({
    headline: {
        textAlign: "center",
        marginBottom: "40px"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    tabContent: {
        display: "flex",
        flexDirection: "column",
    }
}));


export default function RegistrationData() {
    const [loading, setLoading] = useState<boolean>(false);
    const [registrationData, setRegistrationData] = useState<IRegistrationData[]>([]);
    const [selectedRegistrationData, setSelectedRegistrationData] = useState<string | undefined>(undefined);

    const {getToken} = useContext(AuthServiceContext);
    const classes = useStyles();

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        RegisterServiceApi.getRegistrationData(getToken())
            .then(response => {
                const registrationData = response.sort((a: IRegistrationData, b: IRegistrationData) => {
                    return a.username.localeCompare(b.username);
                });
                setRegistrationData(registrationData);
                console.info(registrationData);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getToken]);

    const handleRelease = (releasedData: IRegistrationData) => {
        setLoading(true);
        MemberServiceApi.createMember(releasedData.username, getToken())
            .then(response => {
                const regDataIndex = registrationData.findIndex(data => data.username === releasedData.username);
                registrationData.splice(regDataIndex, 1);
                setRegistrationData(registrationData);
                setSelectedRegistrationData(undefined);
                console.info("released registration data:", response);
                navigate(ROUTES.MEMBERS);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleUpdate = (data: IRegistrationData) => {
        setLoading(true);
        RegisterServiceApi.updateRegistrationData(data, getToken())
            .then((updatedData: IRegistrationData) => {
                const regDataIndex = registrationData.findIndex(data => data.username === updatedData.username);
                registrationData.splice(regDataIndex, 1, updatedData);
                setRegistrationData(registrationData);
                setSelectedRegistrationData(undefined);
                console.info("updated registration data:", updatedData);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleDelete = (deletingData: IRegistrationData) => {
        setLoading(true);
        RegisterServiceApi.deleteRegistrationData(deletingData, getToken())
            .then(() => {
                const regDataIndex = registrationData.findIndex(data => data.username === deletingData.username);
                registrationData.splice(regDataIndex, 1);
                setRegistrationData(registrationData);
                setSelectedRegistrationData(undefined);
                console.info("deleted registration data:", deletingData);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleRegistrationDataSelection = (selectedId: string) => {
        setSelectedRegistrationData(selectedId);
    }

    const registrationDataHeaderColumns: DataHeaderColumn<IRegistrationData>[] = [
        {value: "lastName", sortable: true, node: "Nachname"},
        {value: "firstName", sortable: true, node: "Vorname"},
        {value: "username", sortable: true, node: "Nutzername"},
        {value: "mailAddress", sortable: false, node: "E-Mail-Adresse"},
        {value: "membership", sortable: true, node: "Art der Mitgliedschaft"}
    ];

    const registrationDataDataRows: DataTableRow<IRegistrationData>[] = registrationData.map((data) => ({
        value: data,
        nodes: [
            <>{data.lastName}</>,
            <>{data.firstName}</>,
            <>{data.username}</>,
            <>{data.mailAddress}</>,
            <>{membershipToText(data.membership)}</>
        ],
        detail:
            <RegistrationDataDetail data={data}
                                    onRelease={handleRelease}
                                    onUpdate={handleUpdate}
                                    onDelete={handleDelete}/>
    }));

    const uniqueKeyColumn = "username";

    return (
        <ContentContainer process={loading}>
            <h1 className={classes.headline}>Neue Mitgliederanfragen</h1>
            <div className={classes.content}>
                <div className={classes.tabContent}>
                    <DataTable headerColumns={registrationDataHeaderColumns}
                               dataRows={registrationDataDataRows}
                               uniqueKeyColumn={uniqueKeyColumn}
                               selected={selectedRegistrationData}
                               onSelection={handleRegistrationDataSelection}/>
                </div>
            </div>
        </ContentContainer>
    );
}
