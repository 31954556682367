import React, {ChangeEvent, PropsWithChildren, ReactNode, useState} from "react";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TablePagination, {LabelDisplayedRowsArgs} from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableCell from "@mui/material/TableCell";
import "./DataTable.css";

export type DataHeaderColumn<T> = {
    node: ReactNode;
    sortable: boolean;
    value: keyof T;
};

export type DataTableRow<T> = {
    nodes: ReactNode[];
    detail: ReactNode;
    value: T;
};

export type DataOrder<T> = {
    headerColumn: DataHeaderColumn<T>;
    direction: "asc" | "desc";
};

interface DataTableProps<T> extends PropsWithChildren<any> {
    headerColumns: DataHeaderColumn<T>[];
    dataRows: DataTableRow<T>[];
    uniqueKeyColumn: keyof T;
    defaultOrder?: DataOrder<T>;
    selected: string | undefined;

    onSelection(selectedData: string): void;
}

export default function DataTable<T>(props: DataTableProps<T>) {

    const {headerColumns, dataRows, uniqueKeyColumn, defaultOrder, onSelection, selected} = props;

    const [order, setOrder] = React.useState<DataOrder<T>>(defaultOrder ? defaultOrder : {
        headerColumn: headerColumns[0],
        direction: "asc",
    });
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleSelectClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
        console.info("klick", event.currentTarget.id);
        const currentSelected = event.currentTarget.id;
        if (currentSelected !== selected) {
            onSelection(currentSelected);
        } else {
            onSelection("");
        }
    }

    const isOrderColumn = (headerColumn: DataHeaderColumn<T>): boolean => {
        return order.headerColumn.value === headerColumn.value;
    }

    return (
        <div style={{backgroundColor: "#ffffff"}} className="dataTableContainer">
            <TableContainer style={{borderRadius: "0px"}}>
                <Table>
                    <TableHead className="dataTableHeader">
                        <TableRow>
                            {headerColumns.map((headerColumn: DataHeaderColumn<T>, index: number) => (
                                <TableCell key={index}
                                           sortDirection={headerColumn.sortable && isOrderColumn(headerColumn) ? order.direction : undefined}>
                                    {headerColumn.sortable && (
                                        <TableSortLabel key={index}
                                                        active={isOrderColumn(headerColumn)}
                                                        direction={isOrderColumn(headerColumn) ? order.direction : undefined}
                                                        onClick={() => {
                                                            setOrder({
                                                                headerColumn: headerColumn,
                                                                direction: order.headerColumn.value !== headerColumn.value ? "asc" : order.direction === "asc" ? "desc" : "asc"
                                                            });
                                                        }}>
                                            {headerColumn.node}
                                        </TableSortLabel>)}
                                    {!headerColumn.sortable && (headerColumn.node)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(dataRows, getComparator(order))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((dataRow: DataTableRow<T>, index: number) => {
                                const dataRowValue = (dataRow.value[uniqueKeyColumn] as unknown) as string;
                                const isSelected = dataRowValue === selected;
                                return (
                                    <React.Fragment key={index}>
                                        <TableRow hover role="checkbox"
                                                  tabIndex={-1}
                                                  key={index}
                                                  id={dataRowValue}
                                                  selected={isSelected}
                                                  onClick={handleSelectClick}>
                                            {dataRow.nodes.map((value, index) => (
                                                <TableCell key={index} style={{borderBottom: "none"}}>
                                                    {value}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow key={"technicalData_" + index}>
                                            <TableCell key={index} style={{padding: "0"}}
                                                       colSpan={dataRow.nodes.length}>
                                                <Collapse
                                                    in={isSelected}
                                                    unmountOnExit>
                                                    {dataRow.detail}
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                labelRowsPerPage="Zeilen pro Seite"
                labelDisplayedRows={(labelDisplayedRowsArgs: LabelDisplayedRowsArgs) => {
                    return `${labelDisplayedRowsArgs.from}-${labelDisplayedRowsArgs.to} von ${labelDisplayedRowsArgs.count !== -1 ? labelDisplayedRowsArgs.count : `mehr als ${labelDisplayedRowsArgs.to}`}`;
                }}
                backIconButtonProps={{title: "Vorherige Seite"}}
                nextIconButtonProps={{title: "Nächste Seite"}}
                component="div"
                count={dataRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<T>(order: DataOrder<T>): (a: T, b: T) => number {
    return order.direction === 'desc'
        ? (a, b) => descendingComparator(a, b, order.headerColumn.value)
        : (a, b) => -descendingComparator(a, b, order.headerColumn.value);
}

function stableSort<T>(array: DataTableRow<T>[], comparator: (a: T, b: T) => number): DataTableRow<T>[] {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator((a[0] as DataTableRow<T>).value, (b[0] as DataTableRow<T>).value);
        if (order !== 0) return order;
        return (a[1] as number) - (b[1] as number);
    });
    return stabilizedThis.map((el) => (el[0] as DataTableRow<T>));
}

