import {TextField} from "@mui/material";
import React, {CSSProperties, PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => createStyles({
    backgroundTextArea: {
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#ffffff"
        }
    }
}));

interface BackgroundTextAreaProps extends PropsWithChildren<any> {
    id?: string;
    label?: string;
    variant?: "outlined" | "filled" | "standard";
    rows: number;
    value?: any;
    required?: boolean;
    style?: CSSProperties;
    className?: string;
    editable?: boolean;
    error?: boolean | undefined;
    helperText?: React.ReactNode;

    onChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined): void;
}

export default function BackgroundTextArea(props: BackgroundTextAreaProps) {
    const {id, label, variant, rows, value, required, style, className, error, helperText, onChange} = props;
    const classes = useStyles();

    const editable = props.editable !== undefined ? props.editable : true;

    return (
        <TextField id={id}
                   style={{marginBottom: "20px", ...style}}
                   className={`${editable ? classes.backgroundTextArea + " " : ""}${className}`}
                   error={error}
                   helperText={helperText}
                   label={label}
                   multiline
                   rows={rows}
                   required={required}
                   value={value}
                   disabled={!editable}
                   variant={!!variant ? variant : "standard"}
                   onChange={onChange}>{props.children}</TextField>
    );
}
