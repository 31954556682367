import React, {PropsWithChildren, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import IParticipant from "../../model/association_working/IParticipant";
import {Icon, IconButton, MenuItem} from "@mui/material";
import BackgroundAutocomplete, {OptionType} from "../../components/BackgroundAutocomplete";
import IAssociationWorking from "../../model/association_working/IAssociationWorking";
import BackgroundDropdown from "../../components/BackgroundDropdown";

const useStyles = makeStyles(() => createStyles({
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    participantCancelButton: {
        marginLeft: "5px",
        marginTop: "8px",
        height: "40px"
    },
    participantAddButton: {
        fontSize: "16px",
        marginLeft: "5px",
        marginTop: "auto",
        marginBottom: "auto"
    },
}));

interface AssociationWorkingAddParticipantProps extends PropsWithChildren<any> {
    associationWorking: IAssociationWorking;
    participants: IParticipant[];
    optionTypes: OptionType[];

    onAddParticipant(participantIdentifier: string, trailerCoupling: boolean): void;
}

export default function AssociationWorkingAddParticipant(props: AssociationWorkingAddParticipantProps) {
    const {associationWorking, participants, optionTypes, onAddParticipant} = props;
    const classes = useStyles();

    // model
    const [participantToAdd, setParticipantToAdd] = useState<string>("");
    const [trailerCouplingSupport, setTrailerCouplingSupport] = useState<string>("");
    const [openAddParticipant, setOpenAddParticipant] = useState<boolean>(false);


    const handleAllowedToAddParticipants = () => {
        return associationWorking.neededPersons > participants.length;
    }

    const handleAllowedToAddTrailerCouplingSupport = () => {
        return associationWorking.neededTrailerCouplings > participants
            .filter(participant => participant.trailerCouplingSupport).length;
    }

    const handleAddParticipant = () => {
        onAddParticipant(participantToAdd, trailerCouplingSupport === "true");
        setParticipantToAdd("");
        setTrailerCouplingSupport("");
        setOpenAddParticipant(false);
    }

    const handleCancelParticipant = () => {
        setParticipantToAdd("");
        setTrailerCouplingSupport("");
        setOpenAddParticipant(false);
    }

    return (
        <TextRow>
            {(openAddParticipant && handleAllowedToAddParticipants()) && (<>
                <BackgroundAutocomplete id="AddParticipantInput"
                                        className={classes.leftColumn}
                                        label="Teilnehmer"
                                        selectedContactIdentifier={""}
                                        optionTypes={optionTypes.filter(optionType => !participants.map(value => value.identifier).includes(optionType.value))}
                                        onChange={(newValue: string) => {
                                            setParticipantToAdd(newValue);
                                        }}/>
                <BackgroundDropdown id="add-trailer-coupling-support"
                                    className={classes.rightColumn}
                                    label="AHK"
                                    editable={handleAllowedToAddTrailerCouplingSupport()}
                                    useValue={true}
                                    value={handleAllowedToAddTrailerCouplingSupport() ? trailerCouplingSupport : "Nein"}
                                    defaultValue={trailerCouplingSupport}
                                    nonValue={"false"}
                                    onChange={(event: any) => {
                                        setTrailerCouplingSupport(event.target.value)
                                    }}>
                    <MenuItem value={"false"}>Nein</MenuItem>
                    <MenuItem value={"true"}>Ja</MenuItem>
                </BackgroundDropdown>
                <IconButton className={classes.participantCancelButton}
                            onClick={() => {
                                handleAddParticipant();
                            }}>
                    <Icon>done</Icon>
                </IconButton>
                <IconButton className={classes.participantCancelButton}
                            onClick={() => {
                                handleCancelParticipant();
                            }}>
                    <Icon>clear</Icon>
                </IconButton>
            </>)}
            {(!openAddParticipant && handleAllowedToAddParticipants()) && (<>
                <IconButton onClick={() => {
                    setOpenAddParticipant(true);
                }}><Icon>add</Icon></IconButton>
                <div className={classes.participantAddButton}>Teilnehmer hinzufügen</div>
            </>)}
        </TextRow>
    );
}
