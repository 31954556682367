export const HOME = "/";
export const MEMBERS = "/members";
export const NEW_MEMBER_REQUESTS = "/members/new_requests";
export const GROUPS = "/members/groups";
export const BOATS = "/boats";
export const ASSOCIATION_WORKINGS = "/association-workings";
export const MESSAGES = "/messages";
/* login / user menu */
export const PROFILE = "/profile";
/* footer */
export const LEGAL_NOTICE = "/legal-notice";
export const DATA_PROTECTION = "/data-protection";
