import ISection from "../model/ISection";
import ISite from "../model/ISite";
import {handleResponse} from "./ServiceApi";
import IMessage from "../model/news/IMessage";

const { REACT_APP_CONTENT_SERVICE_URL } = window._env_;

const SITES_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/sites`;
const SECTION_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/sections`;
const MESSAGES_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/messages`;
const NEWSLETTERS_API = `${REACT_APP_CONTENT_SERVICE_URL}/contentmanagement/api/newsletters`;

async function buildRequestOptions(method:string , authHeader?:string) {
    return {
        method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader ? (" Bearer " + authHeader) : ""
        }
    };
}

const ContentServiceApi = {
    createSite: async (site: ISite, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(SITES_API, {
            ...requestOptions,
            body: JSON.stringify(site)
        }).then(handleResponse);
    },
    getSite: async (type: string) => {
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${SITES_API}/${type}`, requestOptions).then(handleResponse);
    },
    updateSite: async (site: ISite, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${SITES_API}/${site.type}`, {
            ...requestOptions,
            body: JSON.stringify(site)
        }).then(handleResponse);
    },
    createSection: async (siteType: string, section: ISection, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(`${SITES_API}/${siteType}/sections`, {
            ...requestOptions,
            body: JSON.stringify(section)
        }).then(handleResponse);
    },
    getSection: async (type: string) => {
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${SECTION_API}/${escape(type)}`, requestOptions).then(handleResponse);
    },
    updateSection: async (section: ISection, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${SECTION_API}/${escape(section.type)}`, {
            ...requestOptions,
            body: JSON.stringify(section)
        }).then(handleResponse);
    },
    deleteSection: async (section: ISection, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${SECTION_API}/${escape(section.type)}`, requestOptions).then(handleResponse);
    }
}

export const MessageServiceApi = {
    getActualMessages: async () => {
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${MESSAGES_API}?quantity=3`, requestOptions)
            .then(handleResponse);
    },
    getMessages: async () => {
        const requestOptions = await buildRequestOptions("GET");
        return fetch(`${MESSAGES_API}`, requestOptions)
            .then(handleResponse);
    },
    getNewsletters: async (token: string) => {
        const requestOptions = await buildRequestOptions("GET", token);
        return fetch(`${NEWSLETTERS_API}`, requestOptions).then(handleResponse);
    },
    createNewsletter: async (message: IMessage, token: string) => {
        const requestOptions = await buildRequestOptions("POST", token);
        return fetch(NEWSLETTERS_API, {
            ...requestOptions,
            body: JSON.stringify(message)
        }).then(handleResponse);
    },
    updateNewsletter: async (message: IMessage, token: string) => {
        const requestOptions = await buildRequestOptions("PUT", token);
        return fetch(`${NEWSLETTERS_API}/${message.number}`, {
            ...requestOptions,
            body: JSON.stringify(message)
        }).then(handleResponse);
    },
    deleteNewsletter: async (message: IMessage, token: string) => {
        const requestOptions = await buildRequestOptions("DELETE", token);
        return fetch(`${NEWSLETTERS_API}/${message.number}`, requestOptions)
            .then(handleResponse);
    }
}

export default ContentServiceApi;
